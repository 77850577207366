@use '../3-helpers/variables';
@use '../3-helpers/mixins';

/*Navbar Styling*/

.site-masthead {

  a,
  .focus-visibility {
    &:focus-visible {
      outline-offset: -4px;
      padding: 8px;
      max-width: fit-content;
      box-shadow: none;
    }
  }

  .li-order-1 {
    order: 1
  }

  .li-order-2 {
    order: 2
  }

  .li-order-3 {
    order: 3
  }

  .li-order-4 {
    order: 4
  }

  .li-order-5 {
    order: 5
  }

  .li-order-6 {
    order: 6
  }

  .li-order-7 {
    order: 7
  }

  .li-order-8 {
    order: 8
  }

  .li-order-9 {
    order: 9
  }

  .li-order-10 {
    order: 10
  }

  .li-order-11 {
    order: 11
  }

  .li-order-12 {
    order: 12
  }

  .text-info {
    color: variables.$link-color-three !important;
  }


  .masthead-dropdown {
    a {
      &:focus-visible {
        outline-offset: 0px !important;
        max-width: fit-content;
      }
    }

    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background: variables.$masthead-dropdown-bg-color;
  }

  .navbar {
    padding: 0px;

    @media only screen and (max-width: variables.$site-body-width-L) {
      .navbar-item-expanded {
        a.nav-link {
          &:not(.product-details-nav-link) {
            color: variables.$white-primary;
            background-color: variables.$secondary-color-navy;
            text-decoration: underline;
          }
        }
      }
    }

    @media only screen and (min-width: variables.$site-body-width-L) {
      ul.navbar-nav {
        align-items: center;
      }
    }

    .logo-container {
      align-items: center;

      a {
        &:focus-visible {
          outline-offset: 1px;
        }
      }

      .masthead-logo {
        max-width: 200px;
        height: 32px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          max-width: 160px;
          max-height: 28px;
        }

        @media only screen and (min-width: variables.$site-body-width-XL) {
          max-height: 40px;
        }
      }

      .unclickable {
        cursor: default;
      }
    }


    .vl {
      border-left: 1px solid variables.$bg-color-shadow;
      height: 24px;
    }


    .masthead-title {
      margin-right: 40px;
      font-family: variables.$heading-font-regular;
      font-weight: variables.$main-font-weight-light;
      font-size: 24px;
      line-height: 24px;

      @media only screen and (max-width: variables.$site-body-width-L) {
        color: variables.$site-mobile-nav-menu-text-color-main;
        margin-right: 0px;
      }
    }


    .dropdown-toggle::after {
      display: none !important;
    }

    //BEGIN Global Nav Hover Styling
    //We do NOT apply this to the language selector, as this contains a child LI which receives this styling instead
    //We do NOT apply this to the get a quote classed list either as this contains a button.
    //We ONLY apply this behaviour to the immediate child (>) of the head-menu in order to avoid drop down megamenu nav item list elements from inheriting these styles.

    ul.head-menu {

      >li.nav-item,
      >li.nav-item-dropdown {
        &:not(.anaval-language-selection):not(.anaval-get-quote) {
          @media only screen and (min-width: variables.$site-body-width-L) {
            //Add transparent border so when we apply coloured border on hover nothing moves around//
            border-top: 4px solid transparent;
          }

          &:hover,
          &:active,
          &.show {
            background-color: variables.$bg-color-gray07;

            @media only screen and (min-width: variables.$site-body-width-L) {
              //Add coloured border on hover and active tab state//
              border-top: 4px solid variables.$site-header-link-hover-color;

              .text-info {
                color: variables.$site-header-link-hover-color !important;
              }
            }
          }

          &:active,
          &.show {
            background-color: variables.$bg-color-gray07;

            @media only screen and (min-width: variables.$site-body-width-L) {
              //Add coloured border on hover and active tab state//
              border-top: 4px solid variables.$site-header-link-active-color;

              .text-info {
                color: variables.$site-header-link-active-color !important;
              }
            }
          }
        }
      }
    }

    //END Global Nav Hover Styling

    ul.navbar-nav {

      li {
        .dropdown-menu {
          margin-top: 0;
        }
      }

      >li {
        a {
          i {
            &::before {
              vertical-align: middle;
            }
          }
        }
      }

      //BEGIN Common styling between nav-item-dropdown and nav-item
      li.nav-item-dropdown,
      li.nav-item {
        &.nav-li {
          @media only screen and (min-width: variables.$site-body-width-L) {
            min-height: 68px;
            display: flex;
            align-items: center;
          }

          a {
            padding: 14px 20px 14px 20px;

            @media only screen and (min-width: variables.$site-body-width-L) {
              display: flex;
              align-items: center;
            }
          }

          .fa-angle-down,
          .fa-angle-up {
            font-size: 30px;
            line-height: 24px;
            float: right;
          }

          /*This position is used to make dropdown look like a panel*/
          &.dropdown {
            position: static;
          }
        }
      }

      //END Common styling between nav-item-dropdown and nav-item


      li.nav-item-dropdown {

        .dropdown-menu {

          @media only screen and (max-width: variables.$site-body-width-L) {
            padding-bottom: 0px;
            padding-top: 0px;
            box-shadow: none;
          }
        }

        &.nav-li {

          &.dropdown {
            @media only screen and (max-width: variables.$site-body-width-L) {
              border-top: 1px solid rgba(255, 255, 255, 0.25);
              border-bottom: 1px solid rgba(255, 255, 255, 0.25);
            }
          }
        }
      }


      li.nav-item {

        .icon-badge-container {
          .aon-icon {

            &.aon-user,
            &.aon-help {
              margin-right: 10px;
            }
          }
        }

        &.nav-li {

          a {
            @media only screen and (min-width: variables.$site-body-width-L) {
              min-height: 64px;
            }
          }

          .masthead-dropdown {
            margin-top: -1px;
          }

          .fa-angle-down,
          .fa-angle-up {
            font-size: 20px;
          }

          &.nav-li-login {
            position: relative;

            &:hover,
            &:active {
              a.nav-link {
                background-color: variables.$bg-color-gray07;

                @media screen and (max-width: variables.$site-body-width-L) {
                  color: variables.$white-primary !important;
                  background-color: variables.$secondary-color-navy;
                  border-top: 0;
                }
              }
            }
          }
        }

        @media all and (min-width: variables.$site-body-width-L) {
          &.navbar-item-expanded {

            &:hover {
              .dropdown-menu {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  /*Masthead Nav bar in mobile mode*/
  @media only screen and (max-width: variables.$site-body-width-L) {

    .navbar {
      background-color: variables.$core-color-blue;

      .masthead-title {
        max-width: 200px;
        color: variables.$site-mobile-nav-menu-text-color-main;
        margin-right: 0px;
      }

      /*Set a fixed width on the toggler so that when it switches between hamburger and X icon no adjacent content moves*/
      .navbar-toggler {
        border: none;
        width: 43px;

        &:hover {
          background-color: inherit;
          border-color: inherit;
          width: 43px;
        }

        &:focus {
          background-color: inherit;
          border-color: inherit;
          width: 43px;
        }
      }

      ul.navbar-nav {

        li.nav-item {

          a,
          p,
          h4 {
            &:not(.product-details-nav-link) {
              color: variables.$site-mobile-nav-menu-text-color-main;
            }
          }

          &.nav-li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.25);

            &.nav-li-login-link {
              border-bottom: 0px;
            }

            &:hover,
            &:active {
              a.nav-link {
                color: variables.$white-primary !important;
                background-color: variables.$secondary-color-navy;
                text-decoration: underline;
              }
            }
          }

          &.nav-li-button {
            padding-left: 20px;
            padding-right: 20px;
          }

          &.nav-li-login {
            border-bottom: none;
          }
        }
      }

      /*Font awesome X icon color*/
      .fa-times {
        color: variables.$link-color-frost-lighter;
      }

      .fal,
      .far,
      .fas,
      .text-info {
        color: variables.$site-mobile-nav-menu-text-color-main !important;
      }

      .main-navbar-controls {

        .fal,
        .far,
        .fas,
        .text-info {
          color: variables.$site-mobile-nav-menu-controls-text-color !important;
        }
      }

      &.navbar-collapsed {
        .main-navbar-controls {

          .fal,
          .far,
          .fas,
          .text-info {
            color: variables.$site-mobile-nav-menu-controls-text-color-collapsed !important;
          }
        }
      }

      .inverse-button {
        color: variables.$button-default-inverse-text-default-color;
        background-color: variables.$link-color-frost;
        border: 1px solid variables.$link-color-frost;

        &:hover {
          background-color: variables.$link-color-frost-lighter;
          border: 1px solid variables.$link-color-frost-lighter;
        }

        &:active {
          background-color: variables.$link-color-frost-darker;
          border: 1px solid variables.$link-color-frost-darker;
        }
      }
    }
  }

  .nav-cart-mobile {
    background-color: variables.$header-color-one;
  }

  .cart-icon-mobile {
    color: variables.$white-primary !important;
  }
}
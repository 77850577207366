@use '../3-helpers/variables';
@use '../3-helpers/mixins';


.basic-card {
  background: variables.$card-bg-color;
  border: 1px solid variables.$card-border-color;
  border-radius: 8px;
  padding: 32px;
  width: 100%;

  a {
    overflow-wrap: anywhere;
  }

  @media only screen and (max-width: variables.$site-body-width-S) {
    border-radius: 0px !important;
    padding: 20px !important;
  }

  &.edit-state {
    background: variables.$card-edit-state-bg-color;
    border-radius: 4px;
    box-shadow: none;
    border: none;
    padding: 20px;
  }

  &.flat-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.solid {
    box-shadow: 0px 0px 0px;
  }

  .center {
    text-align: center;
  }

  .item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  p {
    &.card-text {
        margin-bottom:0px;
    }
  }
}

@use 'variables';

@mixin paragraph ($fontfamily: variables.$main-font-regular, $fontsize: 16px, $lineheight: 20px, $color: variables.$paragraph-color-one, $weight: variables.$main-font-weight-normal, $margin: 0px 0px 16px 0px) {
  font-family: $fontfamily;
  font-size: $fontsize;
  font-weight: $weight;
  line-height: $lineheight;
  color: $color;
  margin: $margin;
}

@mixin heading ($fontfamily, $fontsize, $lineheight, $color: variables.$header-color-one, $margin: 0px 0px 20px 0px, $weight: variables.$main-font-weight-light) {
  font-family: $fontfamily;
  font-size: $fontsize;
  font-weight: $weight;
  line-height: $lineheight;
  color: $color;
  background: none;
  margin: $margin;
}


@mixin link($fontfamly: variables.$main-font-regular, $color: variables.$link-default-color, $viscolor: variables.$link-default-color, $hovcolor: variables.$link-hover-color, $actcolor: variables.$link-active-color, $foccolor: variables.$link-active-color, $textdec: underline, $vistextdec: none, $hovtextdec: none, $acttextdec: none, $foctextdec: none) {
  a {
    font-family: $fontfamly;
    text-decoration: $textdec;
    color: $color;
    cursor: pointer;

    &:visited {
      text-decoration: $vistextdec;
      color: $viscolor;
    }

    &:hover {
      text-decoration: $hovtextdec;
      color: $hovcolor;
    }

    &:active {
      text-decoration: $acttextdec;
      color: $actcolor;
    }

    &:focus {
      text-decoration: $foctextdec;
      color: $foccolor;
    }
  }
}

@mixin icon-link($fontfamly: variables.$main-font-regular, $color: variables.$link-default-color, $viscolor: variables.$link-default-color, $hovcolor: variables.$link-hover-color, $actcolor: variables.$link-active-color, $foccolor: variables.$link-active-color, $textdec: none, $vistextdec: none, $hovtextdec: underline, $acttextdec: none, $foctextdec: none) {
  a.has-icon {
    font-family: $fontfamly;
    text-decoration: $textdec;
    color: $color;
    cursor: pointer;

    &:visited {
      text-decoration: $vistextdec;
      color: $viscolor;
    }

    &:hover {
      text-decoration: $hovtextdec;
      color: $hovcolor;
    }

    &:active {
      text-decoration: $acttextdec;
      color: $actcolor;
    }

    &:focus {
      text-decoration: $foctextdec;
      color: $foccolor;
    }
  }
}


@mixin button($bgcolor, $hoverbgcolor, $focusbgcolor, $activebgcolor, $textcolor: variables.$button-default-text-default-color, $hovertextcolor: variables.$button-default-text-default-color, $focustextcolor: variables.$button-default-text-default-color, $activetextcolor: variables.$button-default-text-default-color, $bordercolor: $bgcolor, $hoverbordercolor: $hoverbgcolor, $focusbordercolor: $focusbgcolor, $activebordercolor: $activebgcolor, $fontweight: variables.$main-font-weight-normal, $fontfamily: variables.$main-font-regular, $minwidth: 130px, $focusvisiblebordercolor: variables.$button-default-text-default-color, $buttonpadding: 12px, $buttonradius: variables.$button-default-radius) {
  &:not(video-js button):not(kendo-pager button):not(kendo-datapager button):not(kendo-popup button):not(.accordion-button) {
    color: $textcolor;
    font-family: $fontfamily;
    font-weight: $fontweight;
    font-size: 16px;
    line-height: 20px;
    border-radius: $buttonradius;
    background-color: $bgcolor;
    border-color: $bordercolor;
    border-width: 1px;
    border-style: solid;
    padding: $buttonpadding;
    min-width: $minwidth;

    &:hover {
      background-color: $hoverbgcolor;
      border-color: $hoverbordercolor;
      color: $hovertextcolor;
      text-decoration: none;
    }

    &[aria-disabled=true],
    &:disabled {
      background-color: $bgcolor;
      border-color: $bordercolor;
      cursor: not-allowed;
      opacity: 0.5;
    }

    &[aria-disabled=true]:hover,
    &:disabled:hover {
      background-color: $bgcolor;
      border-color: $bordercolor;
      cursor: not-allowed;
      opacity: 0.5;
      color: $textcolor;
    }

    &:active {
      background-color: $activebgcolor;
      border-color: $activebordercolor;
      color: $activetextcolor;
      text-decoration: none;
    }

    &:focus {
      background-color: $focusbgcolor;
      border-color: $focusbordercolor;
      color: $focustextcolor;
      text-decoration: none;
    }

    &:focus-visible {
      outline: 2px solid $focusvisiblebordercolor !important;
      outline-offset: 2px;
    }
  }
}

@mixin data-metric-m {
  color: variables.$data-metric-main-color;
  font-family: variables.$main-font-light;
  font-size: 30px;
  line-height: 32px;
  font-weight: variables.$main-font-weight-light;
}

@mixin data-metric-m-table {
  color: variables.$data-metric-main-color;
  font-family: variables.$heading-font-light;
  font-size: 30px !important;
  line-height: 32px;
  font-weight: variables.$main-font-weight-light;
}

@mixin truncate-character-overflow($max-width, $color) {
  overflow: hidden;
  max-width: $max-width;
  text-overflow: ellipsis;
  white-space: nowrap;

  br {
    display: none;
  }

  color: $color !important;
}

@mixin truncate-line-overflow($line) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}
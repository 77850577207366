@use '../3-helpers/variables.scss';

.form-group {
  margin-bottom: variables.$form-group-margin-bottom;

  .fal,
  .far,
  .fas {
    font-size: variables.$icon-form-inputs-default-size;
  }

  .alert {
    @media only screen and (min-width: variables.$site-body-width-S) {
      max-width: variables.$form-control-max-width;
    }
  }
}

.aon-optional,
.aon-required,
.aon-preferred {
  font-style: italic;
  font-family: variables.$main-font-regular;
  font-weight: variables.$main-font-weight-normal;
}

legend,
label {
  float: none;
  font-family: variables.$main-font-bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: variables.$label-margin-bottom;
  color: variables.$label-text-color;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  &.secondary {
    font-weight: variables.$main-font-weight-normal;
    font-family: variables.$main-font-regular;
  }

  &.error {
    color: variables.$feedback-color-error-darker !important;
  }
}

.input-content {
  font-family: variables.$main-font-regular;
  font-weight: variables.$main-font-weight-normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
  color: variables.$input-content-text-color;
}

.inverse-content-block,
.inverse {

  legend,
  span,
  label {
    color: variables.$label-text-color-inverse;

    &.secondary {
      font-weight: variables.$main-font-weight-normal;
    }
  }

  .alert.contextual {
    span {
      color: variables.$alert-contextual-text-color;
    }
  }

  .input-content {
    color: variables.$input-content-text-color-inverse;
  }

  .input-description {
    color: variables.$input-desc-text-color-inverse;
  }

  .input-title {
    color: variables.$input-title-text-color-inverse;
  }
}

.input-description {
  font-family: variables.$main-font-regular;
  font-weight: variables.$main-font-weight-normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  color: variables.$input-desc-text-color;
}


.input-title {
  font-family: variables.$main-font-regular;
  font-weight: variables.$main-font-weight-bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
  color: variables.$input-title-text-color;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  .label {
    margin-right: auto;
  }

  &.error {
    color: variables.$feedback-color-error;
  }

  .icon {
    cursor: pointer;

    &.hide-icon {
      display: none;
    }
  }
}

.input-title-icon {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em;
  font-size: 16px;
  fill: var(--app-color-charcoal);

  &.error {
    fill: variables.$feedback-color-error;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

/*over writing the style input for date picker*/
.calendar-input,
.aon-calendar-input {
  border-radius: 4px !important;

  &:focus {
    z-index: 0 !important;
  }
}

.aon-input-password-wrapper {
  .aon-icon:focus {
    outline: 2px solid black;
    outline-offset: 2px;
  }
}

.aon-two-part-telephone-input {
  display: flex;

  @media only screen and (max-width: variables.$site-body-width-S) {
    flex-direction: column;
  }

  .country-code {
    margin-right: 10px;

    @media only screen and (max-width: variables.$site-body-width-S) {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}

.claim-details-modal  {
  .modal-title,  label {
    font-family: variables.$main-font-bold;
    font-weight: variables.$main-font-weight-normal !important;
  }

}

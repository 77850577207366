@use '../3-helpers/variables';
@use '../3-helpers/mixins';

select {
  position: relative;
  height: 44px;
  width: 100%;
  font-family: variables.$main-font-regular;
  font-weight: variables.$main-font-weight-normal;
  font-size: 16px;
  line-height: 20px;
  color: variables.$textinput-text-color;
  border: 1px solid variables.$select-border-color;
  border-radius: 4px;
  cursor: pointer;
  padding: 0px 34px 0px 12px;
  background-color: transparent;
  text-overflow: ellipsis;

  @media only screen and (min-width: variables.$site-body-width-S) {
    max-width: variables.$form-control-max-width;
  }

  &:hover {
    border: 1px solid variables.$checkbox-border-hover-color;
    outline: 1px auto variables.$checkbox-border-hover-color;
  }

  &:focus-within {
    border: 1px solid variables.$checkbox-focused-outline-color;
    outline: 1px auto variables.$checkbox-focused-outline-color;
  }

  &[aria-disabled=true],
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.aon-form-input-wrapper {
  .select {
    position: relative;

    select {
      appearance: none;
    }

    &:after {
      content: "\f0d7";
      font-family: variables.$font-awesome;
      font-weight: variables.$main-font-weight-bold;
      font-size: 16px;
      color: variables.$select-icon-fill-color;
      line-height: 24px;
      position: absolute;
      z-index: 1;
      top: 10px;
      right: 12px;
      text-align: center;
      pointer-events: none;
    }

    @media only screen and (min-width: variables.$site-body-width-S) {
      max-width: variables.$form-control-max-width;
    }
  }
}

.inverse,
.inverse-content-block {
  select:not(.form-select) {
    color: variables.$select-text-color-inverse;
    border: 1px solid variables.$select-border-color-inverse;

    option {
      background-color: variables.$white;
      color: variables.$select-text-color;
    }
  }

  .aon-form-input-wrapper {
    &.select {
      &:after {
        color: variables.$select-icon-fill-color-inverse;
      }
    }
  }
}


select.error {
  border: 2px solid variables.$feedback-color-error-darker;
  padding: 10px 34px 10px 12px;
}

$fa-fontfamily-pro: 'Font Awesome 6 Pro';
$fa-fontfamily-brand: 'Font Awesome 6 Brands';
$fa-fontfamily-duotone: 'Font Awesome 6 Duotone';

$fa-light-weight: 300; // fal
$fa-regular-weight: 400; // far
$fa-solid-weight: 900; // fas or fa
$fa-brand-weight: 400; // fab
$fa-duotone-weight: 900; // fad

$fa-eye: '\f06e';
$fa-eye-slash: '\f070';
$aon-input-checkbox-list-checked-border-color: #006A9B;
@use '../3-helpers/variables';

xng-breadcrumb {

    .xng-breadcrumb-item,
    a:hover,
    .xng-breadcrumb-trail,
    .xng-breadcrumb-link,
    .xng-breadcrumb-separator {
        color: variables.$breadcrumb-main-color;
    }

    .xng-breadcrumb-trail {
        margin-bottom: 0px;
        font-family: variables.$main-font-regular;
        font-weight: variables.$main-font-weight-normal;
    }

}
@use '../3-helpers/variables';

.divider {
  width: 100%;
  position: relative;
  margin: 0;
  height: 1px;
  background-color: variables.$tbs-divider-color;
}

.dropdown-divider {
  margin: 0px;
  border-top: 1px solid variables.$tbs-divider-color;
}

.mobile-app-separator {
  margin: 0px;
  border-top: 1px solid variables.$tbs-divider-color;
}

/*While the divider class should be used the below will ensure we have consistency of color if an HR tag is used instead*/

hr {
  color: variables.$tbs-divider-color;
  opacity: 1;
}
@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.aon-form-radio:not(.aon-direction-column) {
  margin-bottom: 20px;
  font-family: variables.$main-font-regular;

  &:last-child {
    margin-bottom: 0px !important;
  }

  &:only-child {
    margin-bottom: 20px !important;
  }

  input[type="radio"] {
    opacity: 0;
    margin: 6px;
    position: absolute;
    box-sizing: border-box;
  }

  input[type="radio"]+label {
    font-family: variables.$main-font-regular;
    font-weight: variables.$main-font-weight-normal;
    margin-bottom: 0px;
    position: relative;
    display: inline-block;
    line-height: 24px;
    font-size: 16px;
    vertical-align: middle;
    box-sizing: border-box;
    align-items: flex-start;
  }

  /* enabled state */
  input[type="radio"]+label::before {
    box-sizing: border-box;
    content: '';
    background: #fff;
    border: 1px solid variables.$radio-border-color;
    background-color: rgba(255, 255, 255, .8);
    width: 24px;
    display: inline-block;
    margin-right: 10px;
    height: 24px;
    cursor: pointer;
    text-align: center;
    vertical-align: bottom;
    border-radius: 100%;
    flex-shrink: 0;
  }

  input[type="radio"]+label:hover::before {
    border: 2px solid variables.$radio-border-hover-color;
    cursor: pointer;
  }

  input[type="radio"]:checked+label::before {
    box-sizing: border-box;
    background-color: variables.$radio-bg-checked-color;
    border: 4px solid variables.$radio-border-checked-color;
    box-shadow: inset 0 0 0 2.5px rgba(255, 255, 255, .95);
  }

  input[type="radio"]:checked+label:hover::before {
    border: 4px solid variables.$radio-border-checked-color;
  }

  input[type="radio"]:focus+label::before {
    outline: variables.$radio-focused-outline-color auto 2px;
  }

  input[type=radio]:focus:checked+label::before {
    outline: black auto 2px;
  }

  .inverse input[type="radio"]:focus+label::before {
    outline: variables.$radio-focused-outline-inverse-color auto 2px;
  }

  /* disabled state */
  input[type="radio"]:disabled+label {
    pointer-events: none;
    opacity: 0.5;
  }

  /* error state */
  .error input[type="radio"]+label::before {
    border: 1px solid variables.$feedback-color-error;
  }

  .error input[type="radio"]+label:hover::before {
    border: 2px solid variables.$feedback-color-error;
  }

  .error input[type="radio"]:checked+label::before {
    background-color: variables.$feedback-color-error;
    box-shadow: inset 0 0 0 2.5px rgba(255, 255, 255, .95);
    border: 4px solid variables.$feedback-color-error;
  }
}

.aon-form-radio:last-child {
  margin-bottom: 0px;
}
@use '../3-helpers/variables';

.aon-spin-nested-loading {
  position: relative;

  .aon-spin-spinning+.aon-content-box {
    position: relative;
    transition: opacity 0.4s;
    zoom: 1;
    pointer-events: none;
    user-select: none;
    opacity: 0.7;
    -webkit-filter: blur(0.7px);
    filter: blur(0.7px);
  }

  .aon-spin-spinning {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 4;
  }

  .aon-spin-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px;
    color: azure;
  }

  .dark-spin .aon-spin-loader {
    color: #646464;
  }
}


.widget-loading-spinner {
  &.spinner-border {
    width: 3rem;
    height: 3rem;
    color: variables.$core-color-marine-lighter;
  }
}

aon-spin {
  width: 100%;
  min-height: 60px;
}
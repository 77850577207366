@use '../3-helpers/variables';
@use '../3-helpers/mixins';

html body {
  font-family: variables.$main-font-regular;
}

//Reset bootstrap padding and margin on header and paragraph elements
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
p {
  margin: 0px;
  padding: 0px;
}

//Text Size Base classes

.xs {
  font-size: variables.$text-size-xs !important;
}

.sm {
  font-size: variables.$text-size-sm !important;
}

.lg {
  font-size: variables.$text-size-lg !important;

  > a,
  &:not(.fa-classic, .fa-light, .fa-regular, .fa-solid, .fa-thin, .fal, .far, .fas, .fat, .aon-icon, .strong) {
  font-family: variables.$main-font-light;
  }

}

.xl {
  font-size: variables.$text-size-xl !important;

  > a,
  &:not(.fa-classic, .fa-light, .fa-regular, .fa-solid, .fa-thin, .fal, .far, .fas, .fat, .aon-icon, .strong) {
    font-family: variables.$main-font-light;
  }
}

b,
strong,
.strong {
  font-weight: variables.$main-font-weight-normal;
  font-family: variables.$main-font-bold;

  > a {
    font-family: variables.$main-font-bold;
  }
}

.light {
  font-family: variables.$main-font-light;
  font-weight: variables.$main-font-weight-light;

  > a {
    font-family: variables.$main-font-light;
  }
}

.italic {
  font-style: italic;
}

.color-link {
  color: variables.$link-default-color;

  &.color-2 {
    color: variables.$link-color-two;
  }

  &.color-hide {
    color: variables.$secondary-color-peacock;
  }
}


//Headings

h1,
.h1 {
  @include mixins.heading ($fontfamily: variables.$heading-font-bold, $fontsize: 40px, $lineheight: 52px, $weight: variables.$main-font-weight-bold);

  &.secondary {
    @include mixins.heading ($fontfamily: variables.$heading-font-bold, $fontsize: 36px, $lineheight: 44px);
  }

  &.color-2 {
    color: variables.$header-color-two;
  }

  &.color-3 {
    color: variables.$header-color-three;
  }

  @media screen and (max-width:variables.$site-body-width-S) {
    @include mixins.heading ($fontfamily: variables.$heading-font-bold, $fontsize: 30px, $lineheight: 36px, $weight: variables.$main-font-weight-bold);
  }
}


h2,
.h2 {
  @include mixins.heading ($fontfamily: variables.$heading-font-bold, $fontsize: 36px, $lineheight: 42px, $weight: variables.$main-font-weight-bold);

  &.secondary {
    @include mixins.heading ($fontfamily: variables.$heading-font-bold, $fontsize: 24px, $lineheight: 38px);
  }

  &.color-2 {
    color: variables.$header-color-two;
  }

  &.color-3 {
    color: variables.$header-color-three;
  }

  @media screen and (max-width:variables.$site-body-width-S) {
    @include mixins.heading ($fontfamily: variables.$heading-font-bold, $fontsize: 24px, $lineheight: 38px, $weight: variables.$main-font-weight-bold);
  }
}

h3,
.h3 {
  @include mixins.heading ($fontfamily: variables.$heading-font-bold, $fontsize: 24px, $lineheight: 32px, $weight: variables.$main-font-weight-bold);

  &.secondary {
    @include mixins.heading ($fontfamily: variables.$heading-font-bold, $fontsize: 22px, $lineheight: 24px, $weight: variables.$main-font-weight-bold);
  }

  &.color-2 {
    color: variables.$header-color-two;
  }

  &.color-3 {
    color: variables.$header-color-three;
  }

  @media screen and (max-width:variables.$site-body-width-S) {
    @include mixins.heading ($fontfamily: variables.$heading-font-bold, $fontsize: 20px, $lineheight: 26px, $weight: variables.$main-font-weight-bold);
  }
}

h4,
.h4 {
  @include mixins.heading ($fontfamily: variables.$heading-font-light, $fontsize: 24px, $lineheight: 28px);

  &.secondary {
    @include mixins.heading ($fontfamily: variables.$heading-font-light, $fontsize: 22px, $lineheight: 24px);
  }

  &.color-2 {
    color: variables.$header-color-two;
  }

  &.color-3 {
    color: variables.$header-color-three;
  }

  @media screen and (max-width:variables.$site-body-width-S) {
    @include mixins.heading ($fontfamily: variables.$heading-font-light, $fontsize: 20px, $lineheight: 26px);
  }
}

h5,
.h5 {
  @include mixins.heading ($fontfamily: variables.$heading-font-bold, $fontsize: 20px, $lineheight: 24px, $weight: variables.$main-font-weight-bold);

  &.secondary {
    @include mixins.heading ($fontfamily: variables.$heading-font-bold, $fontsize: 18px, $lineheight: 24px, $weight: variables.$main-font-weight-bold);
  }

  &.color-2 {
    color: variables.$header-color-two;
  }

  &.color-3 {
    color: variables.$header-color-three;
  }

  @media screen and (max-width:variables.$site-body-width-S) {
    @include mixins.heading ($fontfamily: variables.$heading-font-bold, $fontsize: 18px, $lineheight: 24px, $weight: variables.$main-font-weight-bold);
  }
}

h6,
.h6 {
  @include mixins.heading ($fontfamily: variables.$main-font-bold, $fontsize: 16px, $lineheight: 20px, $color: variables.$header-color-two);

  &.secondary {
    @include mixins.heading ($fontfamily: variables.$main-font-bold, $fontsize: 14px, $lineheight: 20px, $color: variables.$header-color-two);
  }

  &.color-2 {
    color: variables.$header-color-three;
  }

  &.color-3 {
    color: variables.$header-color-three;
  }

  text-transform: uppercase;

  @media screen and (max-width:variables.$site-body-width-S) {
    @include mixins.heading ($fontfamily: variables.$heading-font-bold, $fontsize: 14px, $lineheight: 18px, $weight: variables.$main-font-weight-bold);
  }
}
//Paragraphs

p {
  @include mixins.paragraph;

  @media screen and (max-width:variables.$site-body-width-S) {
    font-size: 15px;
  }

  &.lg {
    line-height: variables.$line-height-lg;

    @media screen and (max-width:variables.$site-body-width-S) {
      font-size: 17px !important;
    }
  }

  &.xl {
    line-height: variables.$line-height-xl;
    margin: 0px 0px 20px 0px;

    @media screen and (max-width:variables.$site-body-width-S) {
      font-size: 17px !important;
      line-height: 24px;
    }
  }

  &.sm {
    line-height: variables.$line-height-sm;
    margin: 0px 0px 12px 0px;
  }

  &.xs {
    line-height: variables.$line-height-xs;
    margin: 0px 0px 8px 0px;
  }

  &.color-2 {
    color: variables.$paragraph-color-two;
  }

  &.color-3 {
    color: variables.$paragraph-color-three;
  }

  &.color-4 {
    color: variables.$paragraph-color-four;
  }
}

////Headings & Paragraphs within inverse containers

.inverse-content-block,
.inverse {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: variables.$header-inverse-color;

    &.color-2 {
      color: variables.$header-inverse-color-two;
    }
  }

  p {
    color: variables.$paragraph-inverse-color-one;

    &.color-2 {
      color: variables.$paragraph-inverse-color-two;
    }
  }

  @include mixins.link($color: variables.$link-inverse-default-color,
  $viscolor: variables.$link-inverse-default-color,
  $hovcolor: variables.$link-inverse-hover-color,
  $actcolor: variables.$link-inverse-active-color,
  $foccolor: variables.$link-inverse-active-color);

&.secondary {
  @include mixins.link($color: variables.$link-inverse-default-color-two,
    $viscolor: variables.$link-inverse-default-color-two,
    $hovcolor: variables.$link-inverse-hover-color-two,
    $actcolor: variables.$link-inverse-active-color-two,
    $foccolor: variables.$link-inverse-active-color-two );
}

i {
  color: variables.$icon-inverse-color;
}
}

//Icons - Baseline font awesome colour and size defaults

.fal,
.far,
.fas {
  color: variables.$icon-default-color;
  font-size: variables.$icon-default-size;

  &[role=button] {
    cursor: pointer;
  }

  &.color-2 {
    color: variables.$icon-color-two !important;
  }
}

//Links

@include mixins.link;

@include mixins.icon-link;

.data-metric-lg {
  color: variables.$data-metric-main-color;
  font-family: variables.$main-font-light;
  font-size: 40px;
  line-height: 44px;
  font-weight: variables.$main-font-weight-light;
}

.data-metric-m {
  @include mixins.data-metric-m;
}

.data-metric-m-table {
  @include mixins.data-metric-m-table;
}

/*global class for dotted underline*/
.dotted {
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-position: under;

  @media screen and (max-width:variables.$site-body-width-S) {
    text-decoration: none;
    text-decoration-style: none;
  }
}

//Lists

ul,
ol {
  margin-bottom: variables.$list-default-margin-bottom;
}

.error {
  color: variables.$feedback-color-error-darker;
}

.success {
  color: variables.$feedback-color-success-darker;
}

.warning {
  color: variables.$feedback-color-warning-darker;
}

@use '../3-helpers/variables';
@use '../3-helpers/mixins';


textarea:not(.error) {
  width: 100%;
  font-family: variables.$main-font-regular;
  font-weight: variables.$main-font-weight-normal;
  font-size: 16px;
  line-height: 20px;
  color: variables.$textarea-text-color;
  background-color: variables.$textarea-bg-color;
  border: 1px solid variables.$textarea-border-color;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 12px;

  @media only screen and (min-width: variables.$site-body-width-S) {
    max-width: variables.$form-control-max-width;
  }
}

textarea:not(.error):not(.disabled):hover {
  border: 1px solid variables.$textarea-border-hover-color;
  outline: 1px auto variables.$textarea-border-hover-color;
}

textarea:not(.error):not(.disabled):focus-within {
  border: 1px solid variables.$textinput-border-focus-color;
  outline: 1px auto variables.$textinput-border-focus-color;
}

textarea.error {
  background-color: variables.$white;
  border: 1px solid variables.$feedback-color-error;
  outline: 1px auto variables.$feedback-color-error;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 12px;
}

textarea.error:not(.disabled):focus-within {
  border: 1px solid variables.$feedback-color-error-darker;
  outline: 1px auto variables.$feedback-color-error-darker;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled textarea:disabled {
  background-color: variables.$textarea-disabled-bg-color;
  cursor: not-allowed;
}

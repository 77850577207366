@import "../../node_modules/@progress/kendo-theme-default/dist/all.css";

@import 'bootstrap/scss/bootstrap';
@import "global/0-base/base-dir";
@import "global/1-pages/pages-dir";
@import "global/2-components/components-dir";
@import "global/3-helpers/helpers-dir";

@import "../../node_modules/@fortawesome/fontawesome-pro/css/all.min.css";
@import "../../node_modules/@fortawesome/fontawesome-pro/css/sharp-solid.min.css";

@import "aon-angular-common-styles";

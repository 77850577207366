@use '../../global/3-helpers/variables' as variables;

.k-datepicker .k-input-inner,
.k-input {
    border: none !important;

    .k-button-solid-base {
        background-image: none !important
    }
}

.k-calendar,
.k-datepicker {
    .k-button {
        min-width: 0px !important;
        border: none !important;
        background-color: inherit !important;
        color: inherit!important;

        &:hover {
            color: inherit;
        }
    }
}

.k-calendar .k-calendar-td:focus .k-link,
.k-calendar .k-calendar-td.k-focus .k-link {
    box-shadow: inset 0 0 0 2px variables.$textinput-text-color;
}

.k-calendar .k-calendar-td.k-selected .k-link {
    background-color: variables.$checkbox-bg-checked-color;

    &:hover {
        background-color: variables.$checkbox-bg-checked-color;
    }
}

.k-calendar .k-calendar-view .k-today,
.k-calendar .k-calendar-nav-today {
    color: variables.$checkbox-bg-checked-color;
}

@media print {

    .k-datepicker .k-input-inner,
    .k-input {
        padding-left: 0px;
    }
}
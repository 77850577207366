@use '../3-helpers/variables' as variables;

aon-input-checkbox-list {
  div {
    border: 1px;
    border-style: solid;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;

    &.aon-checkbox-checked {
      border: 2px solid variables.$aon-input-checkbox-list-checked-border-color;
      padding: 19px;
    }
  }
}
@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.w-tab-menu {
  position: relative;
  border-bottom: 1px solid variables.$tab-link-color;
}

.tab-link {
  padding-right: 40px;
  padding-left: 40px;
  border-bottom: 4px solid transparent;
  background-color: transparent;
  color: variables.$tab-link-color;
  font-size: 16px;
  text-transform: uppercase;

  &:hover {
    border-bottom-color: variables.$tab-link-color;
  }

  &.w--current {
    border-bottom-color: variables.$tab-link-current-color;
    background-color: transparent;
    color: variables.$tab-link-current-color;
  }
}

.w-tab-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 8px 20px;
  text-align: left;
  cursor: pointer;
  margin-bottom: -1px;
  font-family: variables.$main-font-bold;

  &:focus {
    outline: 0;
  }
}

.w-tab-content {
  position: relative;
  display: block;
  overflow: hidden;
}

.w-tab-pane {
  position: relative;
  display: none;
}

.w--tab-active {
  display: block;
}

.w-tabs {
  position: relative;

  &:before,
  &:after {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
  }
}


.skip {
  margin: 16px 20px;
  text-decoration: underline;
  font-size: variables.$text-size-sm;
  position: absolute;
  display: inline-block;
  width: 1px;
  height: 1px;
  overflow: hidden;

  &:focus {
    text-decoration: underline;
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    outline: 2px solid;
    outline-offset: 2px;
  }
}
/*Font declarations*/


@font-face {
  font-family: 'Helvetica Now Display Bold';
  src: local('Helvetica Now Display Bold'),
    url('../../../assets/fonts/HelveticaNowDisplay-Bold.woff2') format('woff2'),
    /* Only serve WOFF if necessary. Otherwise, WOFF 2.0 is fine by itself. */
    url('../../../assets/fonts/HelveticaNowDisplay-Bold.woff') format('woff'),
    url('../../../assets/fonts/HelveticaNowDisplay-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Now Display Medium';
  src: local('Helvetica Now Display Medium'),
    url('../../../assets/fonts/HelveticaNowDisplay-Medium.woff2') format('woff2'),
    url('../../../assets/fonts/HelveticaNowDisplay-Medium.woff') format('woff'),
    url('../../../assets/fonts/HelveticaNowDisplay-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Now Display Regular';
  src: local('Helvetica Now Display Regular'),
    url('../../../assets/fonts/HelveticaNowDisplay-Regular.woff2') format('woff2'),
    url('../../../assets/fonts/HelveticaNowDisplay-Regular.woff') format('woff'),
    url('../../../assets/fonts/HelveticaNowDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Now Display Light';
  src: local('Helvetica Now Display Light'),
    url('../../../assets/fonts/HelveticaNowDisplay-Light.woff2') format('woff2'),
    url('../../../assets/fonts/HelveticaNowDisplay-Light.woff') format('woff'),
    url('../../../assets/fonts/HelveticaNowDisplay-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Now Text Regular';
  src: local('Helvetica Now Text'),
    url('../../../assets/fonts/HelveticaNowText-Regular.woff2') format('woff2'),
    url('../../../assets/fonts/HelveticaNowText-Regular.woff') format('woff'),
    url('../../../assets/fonts/HelveticaNowText-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Now Text Light';
  src: local('Helvetica Now Text Light'),
    url('../../../assets/fonts/HelveticaNowText-Light.woff2') format('woff2'),
    url('../../../assets/fonts/HelveticaNowText-Light.woff') format('woff'),
    url('../../../assets/fonts/HelveticaNowText-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Now Text Bold';
  src: local('Helvetica Now Text Bold'),
    url('../../../assets/fonts/HelveticaNowText-Bold.woff2') format('woff2'),
    url('../../../assets/fonts/HelveticaNowText-Bold.woff') format('woff'),
    url('../../../assets/fonts/HelveticaNowText-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Now Text Medium';
  src: local('Helvetica Now Text Medium'),
    url('../../../assets/fonts/HelveticaNowText-Medium.woff2') format('woff2'),
    url('../../../assets/fonts/HelveticaNowText-Medium.woff') format('woff'),
    url('../../../assets/fonts/HelveticaNowText-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'HansSans';
  src: url('../../../assets/fonts/SourceHanSansCN-VF.ttf') format('truetype');
}

@font-face {
  font-family: 'Arial Unicode MS';
  src: url('../../../assets/fonts/arial-unicode-ms.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansDevanagari';
  src: url('../../../assets/fonts/NotoSansDevanagari-VariableFont_wdth\,wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans JP Thin Bold';
  src: local('Noto Sans JP Thin Bold'), url('../../../assets/fonts/NotoSansJP/noto-sans-jp-japanese-700-normal.woff2') format('woff2'), url('../../../assets/fonts/NotoSansJP/noto-sans-jp-japanese-700-normal.woff') format('woff'), url('../../../assets/fonts/NotoSansJP/noto-sans-jp-japanese-700-normal.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans JP Thin Regular';
  src: local('Noto Sans JP Thin Regular'), url('../../../assets/fonts/NotoSansJP/noto-sans-jp-japanese-400-normal.woff2') format('woff2'), url('../../../assets/fonts/NotoSansJP/noto-sans-jp-japanese-400-normal.woff') format('woff'), url('../../../assets/fonts/NotoSansJP/noto-sans-jp-japanese-400-normal.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans JP Thin Light';
  src: local('Noto Sans JP Thin Light'), url('../../../assets/fonts/NotoSansJP/noto-sans-jp-japanese-300-normal.woff2') format('woff2'), url('../../../assets/fonts/NotoSansJP/noto-sans-jp-japanese-300-normal.woff') format('woff'), url('../../../assets/fonts/NotoSansJP/noto-sans-jp-japanese-300-normal.ttf') format('truetype');
}

#download {
  .fa-print {
    pointer-events: none;
  }
}

@media print {

  #download,
  #print-date-field {
    display: none !important;
  }

  .app-header-sleeve {
    visibility: hidden;

    #Logo {
      visibility: visible;
    }
  }
}
@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled input:disabled {
  background-color: variables.$textinput-disabled-bg-color;
  cursor: not-allowed;
}

.text-input,
input[type="number"],
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="password"] {
  height: 44px;
  width: 100%;
  font-family: variables.$main-font-regular;
  font-weight: variables.$main-font-weight-normal;
  font-size: 16px;
  line-height: 20px;
  color: variables.$textinput-text-color;
  background-color: variables.$textinput-bg-color;
  border: 1px solid variables.$textinput-border-color;
  border-radius: 4px;
  padding: 12px;

  @media only screen and (min-width: variables.$site-body-width-S) {
    max-width: variables.$form-control-max-width;
  }
}

aon-input-password {
  input[type="text"] {
    padding-right: 40px !important;
  }
}

.text-input:not(.error):not(.disabled):focus-within,
input[type="number"]:not(.error):not(.disabled):focus-within,
input[type="tel"]:not(.error):not(.disabled):focus-within,
input[type="email"]:not(.error):not(.disabled):focus-within,
input[type="text"]:not(.error):not(.disabled):focus-within,
input[type="password"]:not(.error):not(.disabled):focus-within {
  border: 1px solid variables.$textinput-border-focus-color;
  outline: 1px auto variables.$textinput-border-focus-color;
}

.text-input:not(.error):not(.disabled):not(:focus-within):hover,
input[type="number"]:not(.error):not(.disabled):not(:focus-within):hover,
input[type="text"]:not(.error):not(.disabled):not(:focus-within):hover+.input-group-text button,
input[type="tel"]:not(.error):not(.disabled):not(:focus-within):hover,
input[type="email"]:not(.error):not(.disabled):not(:focus-within):hover,
input[type="text"]:not(.error):not(.disabled):not(:focus-within):hover,
input[type="password"]:not(.error):not(.disabled):not(:focus-within):hover {
  border: 1px solid variables.$textinput-border-hover-color;
  outline: 1px auto variables.$textinput-border-hover-color;
}


input[type="text"]:not(.error):not(.disabled):hover+.input-group-text button {
  border-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
}


.text-input.error,
input[type="number"].error,
input[type="tel"].error,
input[type="email"].error,
input[type="text"].error,
input[type="password"].error {
  border: 1px solid variables.$feedback-color-error-darker !important;
  outline: 1px auto variables.$feedback-color-error-darker !important;
}

.text-input.error:not(.disabled):focus-within,
input[type="number"].error:not(.disabled):focus-within,
input[type="tel"].error:not(.disabled):focus-within,
input[type="email"].error:not(.disabled):focus-within,
input[type="text"].error:not(.disabled):focus-within,
input[type="password"].error:not(.disabled):focus-within {
  border: 1px solid variables.$feedback-color-error-darker !important;
  outline: 1px auto variables.$feedback-color-error-darker;
}

.inverse-content-block,
.inverse {

  .text-input,
  input[type="number"],
  input[type="tel"],
  input[type="email"],
  input[type="text"],
  input[type="password"] {
    color: variables.$textinput-text-color-inverse;
    border: 1px solid variables.$textinput-border-color-inverse;
  }

  .text-input:not(.error):not(.disabled):hover,
  input[type="number"]:not(.error):not(.disabled):hover,
  input[type="text"]:not(.error):not(.disabled):hover+.input-group-text button,
  input[type="tel"]:not(.error):not(.disabled):hover,
  input[type="email"]:not(.error):not(.disabled):hover,
  input[type="text"]:not(.error):not(.disabled):hover,
  input[type="password"]:not(.error):not(.disabled):hover {
    border: 1px solid variables.$textinput-border-color-inverse;
    outline: 1px auto variables.$textinput-border-color-inverse;
  }

  .text-input:not(.error):not(.disabled):focus-within,
  input[type="number"]:not(.error):not(.disabled):focus-within,
  input[type="tel"]:not(.error):not(.disabled):focus-within,
  input[type="email"]:not(.error):not(.disabled):focus-within,
  input[type="text"]:not(.error):not(.disabled):focus-within,
  input[type="password"]:not(.error):not(.disabled):focus-within {
    border: 1px solid variables.$textinput-border-focus-color-inverse;
    outline: 1px auto variables.$textinput-border-focus-color-inverse;

  }

  .text-input.error:not(.disabled):focus-within,
  input[type="number"].error:not(.disabled):focus-within,
  input[type="tel"].error:not(.disabled):focus-within,
  input[type="email"].error:not(.disabled):focus-within,
  input[type="text"].error:not(.disabled):focus-within,
  input[type="password"].error:not(.disabled):focus-within {
    border: 1px solid variables.$textinput-border-color-inverse !important;
    outline: 1px auto variables.$textinput-border-color-inverse !important;
  }

  input[type="email"].ng-invalid.ng-touched,
  input[type="tel"].ng-invalid.ng-touched,
  input[type="text"].ng-invalid.ng-touched,
  input[type="password"].ng-invalid.ng-touched {
    border: 1px solid variables.$textinput-border-color-inverse !important;
    outline: 1px auto variables.$textinput-border-color-inverse !important;
  }

  label {
    &.error {
      color: variables.$textinput-text-color-inverse !important;
    }
  }

  .form-group {
    i {
      color: variables.$textinput-icon-color-inverse;
    }
  }

  .disabled {
    opacity: 1;
  }
}


.form-group,
.modal-dialog {
  position: relative;

  .fa-eye,
  .fa-eye-slash,
  .fa-calendar {
    position: absolute;
    right: 12px;
    line-height: 44px;
    cursor: pointer;
  }
}

.modal-dialog {

  .fa-eye,
  .fa-eye-slash,
  .fa-calendar,
  .fa-info-circle {
    color: variables.$modal-input-icon-color;
  }
}

.error {
  label {
    color: variables.$feedback-color-error-darker !important;
  }
}

input[type="email"].ng-invalid.ng-touched,
input[type="tel"].ng-invalid.ng-touched,
input[type="text"].ng-invalid.ng-touched,
input[type="password"].ng-invalid.ng-touched {
  border: 1px solid variables.$feedback-color-error-darker !important;
  outline: 1px auto variables.$feedback-color-error-darker !important;
}

input[type="email"].ng-invalid.ng-touched:focus-within,
input[type="tel"].ng-invalid.ng-touched:focus-within,
input[type="text"].ng-invalid.ng-touched:focus-within,
input[type="password"].ng-invalid.ng-touched:focus-within {
  border: 1px solid variables.$feedback-color-error-darker !important;
  outline: 1px auto variables.$feedback-color-error-darker !important;
}

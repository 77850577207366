@use '../../global/3-helpers/variables' as variables;

html {
    body {
        .app-content-sleeve {

            /* error state */
            aon-input-text.ng-invalid.ng-touched input[type="text"]:not(.disabled),
            aon-textarea.ng-invalid.ng-touched textarea:not(.disabled),
            aon-input-dropdown.ng-invalid.ng-touched select:not(.disabled),
            aon-input-tel.ng-invalid.ng-touched input[type="tel"]:not(.disabled),
            aon-input-email.ng-invalid.ng-touched input[type="email"]:not(.disabled),
            aon-input-radio-list.ng-invalid.ng-touched input[type="radio"]+label::before,
            aon-input-checkbox.ng-invalid.ng-touched input[type="checkbox"]+label::before,
            aon-input-checkbox-list.ng-invalid.ng-touched input[type="checkbox"]+label::before,
            aon-input-multiselect.ng-invalid.ng-touched .k-multiselect,
            aon-input-currency.ng-invalid.ng-touched .k-input-inner,
            aon-input-numeric.ng-invalid.ng-touched .k-input-inner,
            aon-input-date.ng-invalid.ng-touched .k-datepicker,
            aon-input-password.ng-invalid.ng-touched input[type="text"]:not(.error):not(.disabled),
            aon-input-password.ng-invalid.ng-touched input[type="password"]:not(.disabled) {
                border: 1px solid variables.$feedback-color-error-darker !important;
                outline: 1px auto variables.$feedback-color-error-darker !important;
                box-shadow: none !important;
            }

            aon-input-text.ng-invalid.ng-touched input[type="text"]:not(.disabled):focus-within,
            aon-textarea.ng-invalid.ng-touched textarea:not(.disabled):focus-within,
            aon-input-dropdown.ng-invalid.ng-touched select:not(.disabled):focus-within,
            aon-input-tel.ng-invalid.ng-touched input[type="tel"]:not(.disabled):focus-within,
            aon-input-email.ng-invalid.ng-touched input[type="email"]:not(.disabled):focus-within,
            aon-input-radio-list.ng-invalid.ng-touched input[type="radio"]:focus+label::before,
            aon-input-checkbox.ng-invalid.ng-touched input[type="checkbox"]:focus+label::before,
            aon-input-checkbox-list.ng-invalid.ng-touched input[type="checkbox"]:focus+label::before,
            aon-input-multiselect.ng-invalid.ng-touched .k-multiselect:focus-within,
            aon-input-currency.ng-invalid.ng-touched .k-input-inner:focus-within,
            aon-input-numeric.ng-invalid.ng-touched .k-input-inner:focus-within,
            aon-input-date.ng-invalid.ng-touched .k-datepicker:focus-within,
            aon-input-password.ng-invalid.ng-touched input[type="text"]:not(.error):not(.disabled):focus-within,
            aon-input-password.ng-invalid.ng-touched input[type="password"]:not(.disabled):focus-within {
                border: 1px solid variables.$feedback-color-error-darker !important;
                outline: 1px auto variables.$feedback-color-error-darker !important;
                box-shadow: none;
            }

            &.dark-mode {

                .inverse,
                .inverse-content-block {

                    aon-input-text.ng-invalid.ng-touched input[type="text"]:not(.disabled),
                    aon-textarea.ng-invalid.ng-touched textarea:not(.disabled),
                    aon-input-dropdown.ng-invalid.ng-touched select:not(.disabled),
                    aon-input-tel.ng-invalid.ng-touched input[type="tel"]:not(.disabled),
                    aon-input-email.ng-invalid.ng-touched input[type="email"]:not(.disabled),
                    aon-input-radio-list.ng-invalid.ng-touched input[type="radio"]+label::before,
                    aon-input-checkbox.ng-invalid.ng-touched input[type="checkbox"]+label::before,
                    aon-input-checkbox-list.ng-invalid.ng-touched input[type="checkbox"]+label::before,
                    aon-input-multiselect.ng-invalid.ng-touched .k-multiselect,
                    aon-input-currency.ng-invalid.ng-touched .k-input-inner,
                    aon-input-numeric.ng-invalid.ng-touched .k-input-inner,
                    aon-input-date.ng-invalid.ng-touched .k-datepicker,
                    aon-input-password.ng-invalid.ng-touched input[type="text"]:not(.error):not(.disabled),
                    aon-input-password.ng-invalid.ng-touched input[type="password"]:not(.disabled) {
                        border: 1px solid variables.$textinput-border-color-inverse !important;
                        outline: 1px auto variables.$textinput-border-color-inverse !important;
                    }

                    aon-input-text.ng-invalid.ng-touched input[type="text"]:not(.disabled):focus-within,
                    aon-textarea.ng-invalid.ng-touched textarea:not(.disabled):focus-within,
                    aon-input-dropdown.ng-invalid.ng-touched select:not(.disabled):focus-within,
                    aon-input-tel.ng-invalid.ng-touched input[type="tel"]:not(.disabled):focus-within,
                    aon-input-email.ng-invalid.ng-touched input[type="email"]:not(.disabled):focus-within,
                    aon-input-radio-list.ng-invalid.ng-touched input[type="radio"]:focus+label::before,
                    aon-input-checkbox.ng-invalid.ng-touched input[type="checkbox"]:focus+label::before,
                    aon-input-checkbox-list.ng-invalid.ng-touched input[type="checkbox"]:focus+label::before,
                    aon-input-multiselect.ng-invalid.ng-touched .k-multiselect:focus-within,
                    aon-input-currency.ng-invalid.ng-touched .k-input-inner:focus-within,
                    aon-input-numeric.ng-invalid.ng-touched .k-input-inner:focus-within,
                    aon-input-date.ng-invalid.ng-touched .k-datepicker:focus-within,
                    aon-input-password.ng-invalid.ng-touched input[type="text"]:not(.error):not(.disabled):focus-within,
                    aon-input-password.ng-invalid.ng-touched input[type="password"]:not(.disabled):focus-within {
                        border: 1px solid variables.$textinput-border-color-inverse !important;
                        outline: 1px auto variables.$textinput-border-color-inverse !important;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
@use '../3-helpers/variables';

app-product-coverage,
app-trs-dashboard,
app-product-case-study,
.T3,
app-quote-flow {
  .accordion > .accordion-item {
    overflow: visible;

    > .accordion-header {
      margin-bottom: 0px;
    }
  }


  .tooltip {
    position: relative;
    z-index: unset;
    opacity: 1;
    font-family: variables.$main-font-regular;
  }

  .tooltip .tooltiptext {
    font-size: 16px;
    line-height: 24px;
    visibility: hidden;
    max-width: variables.$form-control-max-width;
    background-color: variables.$bg-color-slate;
    color: variables.$white;
    border-radius: 4px;
    padding: 12px;
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 0px;
    box-shadow: 0px 2px 12px black;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 20px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent variables.$bg-color-slate transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}

.inline-hoverable {
  display: inline-block;
  position: relative;
}

.inline-hoverable .tooltip-content {
  font-size: 16px;
  line-height: 24px;
  visibility: hidden;
  max-width: variables.$form-control-max-width;
  background-color: variables.$bg-color-slate;
  color: variables.$white;
  border-radius: 4px;
  padding: 12px;
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 0px;
  box-shadow: 0px 2px 12px black;
}

.inline-hoverable .tooltip-content::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 20px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent variables.$bg-color-slate transparent;
}

.inline-hoverable:hover .tooltip-content {
  visibility: visible;
}

app-product-case-study {

  // Mockup required there are no margin on bottom of item title
  .margin-bottom-zero {
    margin: 0 0 0px !important;
  }
}

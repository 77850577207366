//Provides shorthand classes for padding and margin control using px if Bootstrap 4 em approach is not desired.
@use './mixins';

$spaceamounts: (
  0,
  2,
  3,
  4,
  5,
  6,
  8,
  9,
  10,
  12,
  14,
  15,
  16,
  18,
  20,
  24,
  25,
  28,
  30,
  32,
  35,
  36,
  40,
  42,
  44,
  45,
  48,
  50,
  56,
  60,
  72,
  75,
  80,
  100,
  120,
  150,
  200
); // Adjust this to include the pixel amounts you need.
$sides: (
  top,
  bottom,
  left,
  right
); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.thumbnail-img {
  width: 100px !important;
}

.uc-first-letter {
  text-transform: lowercase;

  ::first-letter {
    text-transform: uppercase;
  }

  div,
  span,
  p {
    ::first-letter {
      text-transform: uppercase;
    }
  }
}

.no-text-transform {
  text-transform: none;
}

.bg-transparent {
  background-color: transparent !important;
}

.display-on-load {
  display: none;
}

.bg-overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(76, 77, 79, 0.70);
  pointer-events: none;
  z-index: 10;
  opacity: 1;
  position: fixed;
}


.focus-visibility {
  &:focus-visible {
    outline: 2px solid black !important;
    outline-offset: 2px;
  }
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.z-index-4 {
  z-index: 4 !important;
}

.z-index-5 {
  z-index: 5 !important;
}

.z-index-6 {
  z-index: 6 !important;
}

.z-index-7 {
  z-index: 7 !important;
}

.z-index-8 {
  z-index: 8 !important;
}

.z-index-9 {
  z-index: 9 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.z-index-11 {
  z-index: 11 !important;
}

.anchor-footer-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

@each $truncateLine in (1, 2, 3, 4, 5) {
  .truncate-line-#{$truncateLine} {
    @include mixins.truncate-line-overflow($truncateLine);
  }
}

@media print {
  .hide-print {
    display: none;
  }
}

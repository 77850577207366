@use '../3-helpers/variables';

body {
    .ie-div-position-customer-chat #chat-div-wrap {
        right: 5%;
    }
}

app-root {

    .chat-unavailable-div {
        &.chat-button {
            background: red;
            border-radius: 30px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            color: #fff;
            cursor: not-allowed;
            display: block;
            font: 16px;
            font-family: 'roboto', sans-serif;
            height: 48px;
            position: fixed;
            transition-duration: 0.3s;
            width: 147px;
            right: 30px;
            bottom: 20px;
            z-index: 1;
        }

        &.chat-button-rectangle {
            height: 48px;
            transition-duration: 0.3s;
            width: 147px;
        }

        .chat-button-min-text {
            color: #fff;
            font-size: 12px;
            font-family: variables.$main-font-bold;
            letter-spacing: 0;
            line-height: 24px;
            margin: 0 10px 0 2px;
            opacity: 1;
            overflow: hidden;
            position: absolute;
            right: 0;
            text-align: left;
            text-overflow: ellipsis;
            top: 12px;
            white-space: nowrap;
            width: 93px;
        }

        .chat-icons-max {
            cursor: not-allowed;
            display: block;
            height: 20px;
            margin: 14px 12px 14px 20px;
            position: relative;
            transition: color 0.7s ease-in, background-color 0.7s ease-in, transform 0.7s ease;
            width: 20px;
        }

        .svg-icon {
            fill: rgb(255, 255, 255);
        }

    }

    .nic-chat-fab-container {
        position: fixed;
        z-index: 1;
        bottom: 40px;
        right: 5%;

        .chat-available-div {
            padding: 15px;
            border-radius: 30px;

            .chat-content {
                span {
                    display: flex;
                    cursor: pointer;

                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
@use '../3-helpers/variables';
@use '../3-helpers/mixins';

//Banner Alerts

.aon-alert,
.alert {
  border-radius: 4px 4px 4px 4px;
  font-weight: variables.$main-font-weight-normal;
  width: 100%;

  i {

    &.fal,
    &.fas,
    &.far {
      font-size: 20px;
      margin-right: 10px;
    }
  }

  .title,
  .message {
    display: flex;
    align-items: center;
  }

  .title {
    font-weight: variables.$main-font-weight-bold;
    justify-content: space-between;

    .title-content {
      display: flex;
      align-items: center;

      //THIS CLASS IS ONLY USED IN THE TITLE CONTAINER WHEN THERE IS NO OTHER CONTENT IN THE MESSAGE, IE THE HEADING IS THE MESSAGE//
      span {
        &.msg {
          font-weight: variables.$main-font-weight-light;
          line-height: 20px;
        }
      }
    }
  }

  &.banner {
    color: variables.$alert-banner-text-color;
    padding: 20px;
    border-top: 6px solid;
    border-right-width: 0;
    border-left-width: 0;
    margin-bottom: 20px;

    ul {
      margin-top: 12px;
      margin-bottom: 0px;

      li:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    p {
      margin: 12px 0px 0px 0px;
      color: variables.$alert-banner-text-color;
    }

    &.success {
      border-top-color: variables.$feedback-color-success-darker;
      background-color: variables.$feedback-color-success-lighter;

      i {
        color: variables.$feedback-color-success-darker;
      }
    }

    &.warning {
      border-top-color: variables.$feedback-color-warning-darker;
      background-color: variables.$feedback-color-warning-lighter;

      i {
        color: variables.$feedback-color-warning-darker;
      }
    }

    &.error {
      border-top-color: variables.$feedback-color-error-darker;
      background-color: variables.$feedback-color-error-lighter;

      i {
        color: variables.$feedback-color-error-darker;
      }
    }

    &.info {
      border-top-color: variables.$feedback-color-info-darker;
      background-color: variables.$feedback-color-info-lighter;

      i {
        color: variables.$feedback-color-info-darker;
      }
    }

    i {
      line-height: 24px;
      &.fa-times {
        color: variables.$alert-banner-text-color;
        text-align: right;
        margin: 0px;
        opacity: 1;
      }
    }
  }

  &.aon-contextual-alert,
  &.contextual {
    color: variables.$alert-contextual-text-color;
    padding: 12px;
    border-top: 0px;
    margin-bottom: 8px;

    >div {
      display: flex;
      align-items: center;
    }

    p {
      margin: 0px;
    }

    i {
      line-height: 28px;
    }


    &.success {
      background-color: variables.$feedback-color-success-lighter;

      i {
        color: variables.$feedback-color-success-darker;
      }
    }

    &.warning {
      background-color: variables.$feedback-color-warning-lighter;

      i {
        color: variables.$feedback-color-warning-darker;
      }
    }

    &.error {
      background-color: variables.$feedback-color-error-lighter;

      i {
        color: variables.$feedback-color-error-darker;
      }
    }

    &.info {
      background-color: variables.$feedback-color-info-lighter;

      i {
        color: variables.$feedback-color-info-darker;
      }
    }
  }
}

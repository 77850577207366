@use '../3-helpers/variables' as variables;
@use '../3-helpers/mixins' as mixins;

aon-input-password {

  .aon-show-text {
    @include mixins.aon-icon(variables.$fa-eye, $fontweight: variables.$fa-light-weight);
  }

  .aon-hide-text {
    @include mixins.aon-icon(variables.$fa-eye-slash, $fontweight: variables.$fa-light-weight);
  }

}
// Sensitive Data Dynamic Content masking based on CSS
.sensitive-data:before {
    content: var(--prefix-content, "") var(--symbol, "") var(--value, "") var(--suffix-content, "");
}

.sensitive-data {
    &.show-symbol-right:before {
        content: var(--prefix-content, "") var(--value, "") var(--symbol, "") var(--suffix-content, "");
    }
}

.sensitive-data-control-active {
    .sensitive-data:before {
        content: var(--prefix-content, "") var(--symbol, "") "-" var(--suffix-content, "");
    }

    .sensitive-data {
        &.show-symbol-right:before {
            content: var(--prefix-content, "") "-" var(--symbol, "") var(--suffix-content, "");
        }
    }

    kendo-pdf-document {
        .sensitive-data:before {
            content: var(--prefix-content, "") var(--symbol, "") var(--value, "") var(--suffix-content, "");
        }

        .sensitive-data {
            &.show-symbol-right:before {
                content: var(--prefix-content, "") var(--value, "") var(--symbol, "") var(--suffix-content, "");
            }
        }
    }
}
@use '../3-helpers/variables';
@use '../3-helpers/mixins';


//Breakpoint width placeholder, set these once here and extend it wherever required for use.
@media print {
  #app-header-sleeve-print {
    position: relative !important;
  }
}

%breakpoint-widths {

  @media only screen and (min-width: 1600px) {
    width: variables.$site-body-width-XL;
  }

  @media only screen and (max-width: 1600px) {
    width: variables.$site-body-width-XL;
  }

  @media only screen and (max-width: 1200px) {
    width: variables.$site-body-width-L;
  }

  @media only screen and (max-width: 992px) {
    width: variables.$site-body-width-M;
  }

  @media only screen and (max-width: 768px) {
    width: variables.$site-body-width-S;
  }

  @media only screen and (max-width: 576px) {
    width: 100%;

  }
}

html {

  *:focus {
    outline: none;
  }

  background-color: variables.$site-canvas-color;

  body {
    overflow-x: hidden;
    //Override bootstrap body text color to our own default as a catch all
    color: variables.$high-emphasis-primary-text-color;

    .skinny-footer-active {
      .inverse {
        min-height: calc(100vh - 156px);
      }
    }

    .inverse-content-block,
    .inverse {
      background-color: variables.$inverse-container-color !important;
      min-height: calc(100vh - 504px);
    }

    /*USE THIS FOR CONTENT BLOCKS - INVERSE SHOULD ONLY BE USED ON PAGE BODY, OR SIMILAR FULL PAGE WRAPPER TAGS AS IT SETS A MIN-HEIGHT*/
    .inverse-content-block {
      min-height: unset;
    }

    width: 100%;

    .app-header-sleeve {

      a {
        text-decoration: none;
      }

      //fixed to relative
      position: relative;
      top: 0px;
      left: 0px;
      width: 100%;
      background-color: variables.$site-header-bg-color;
      box-shadow: 0 6px 6px -6px silver;
      z-index: 99;
      min-height: variables.$site-header-height;

      @import "template_masthead";
    }

    .app-content-sleeve {
      margin-top: 0;
      min-height: calc(100vh - 504px);

      .page {
        background-color: variables.$page-background-color;
        min-height: calc(100vh - 504px);
      }

      &.skinny-footer-active {
        min-height: calc(100vh - 156px);

        .page {
          min-height: calc(100vh - 156px);
        }
      }
    }

    .app-footer-sleeve {
      @import "template_footer";
    }



    .page-content-sleeve {
      margin: 0 auto;
      height: 100%;
      width: 1200px;

      @media only screen and (max-width: 1200px) {
        width: 100%;
      }

      max-width: 100%;

      @media only screen and (max-width: 3500px) {
        padding-left: 40px;
        padding-right: 40px;
      }

      //Below 1600px our center container takes up 100% available space with padding left and right of 40px
      @media only screen and (max-width: 1600px) {
        padding-left: 40px;
        padding-right: 40px;
      }

      //Below 575px reduce our center container padding to 20px
      @media only screen and (max-width: 575px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

@use '../3-helpers/variables';
@use '../3-helpers/mixins';


footer {

  &.skinny-footer {
    &.page-footer {
      padding: 30px 20px;
      min-height: 93px;
      bottom: 0;

      a {
        margin-right: 20px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          margin-right: 12px;
        }
        
        &:focus-visible {
          outline: 2px solid variables.$white !important;
          outline-offset: 2px;
        }

      }

      .light-footer-a {
        color: variables.$site-footer-link-text-color;
      }

      .skinny-footer-div {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        ul[role=list] {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 0px;
          margin: 0px;
          list-style-type: none;

          >li {
            margin: 0px;
          }
        }

        @media only screen and (max-width: variables.$site-body-width-M) {
          flex-direction: column;
          align-items: initial;

          div:first-child {
            margin-bottom: 20px;
          }
        }

        &.light-footer {
          .footer-copyright-icon {
            margin-bottom: 0px;
          }

          @media only screen and (min-width: variables.$site-body-width-S) and (max-width: variables.$site-body-width-M) {
            flex-direction: unset;
            align-items: end;

            .footer-copyright-icon {
              margin-bottom: 20px;
            }
          }

          @media only screen and (max-width: variables.$site-body-width-S) {
            flex-direction: column;
            align-items: initial;
          }
        }
      }
    }
  }

  &.page-footer {
    background-color: variables.$site-footer-bg-color;
    margin-top: 0px; //Let last content block on page determine distance from footer.
    padding: 80px 40px;
    min-height: 440px;

    .brand-logo {
      height: 28px;
      width: 61px;
      margin-bottom: 20px;
    }

    h3,
    h6,
    svg,
    label {
      fill: variables.$site-footer-main-text-color;
      color: variables.$site-footer-main-text-color;

      g {
        fill: variables.$site-footer-main-text-color;
      }
    }

    p {
      &.h6 {
        color: variables.$site-footer-main-text-color;
      }

      color: variables.$site-footer-main-text-color-two;

      &.footer-copyright-icon {
        line-height: 16px;
      }
    }


    &:not(.secondary){
      .focus-visibility , li a{
        &:focus-visible {
          outline: 2px solid variables.$white !important;
          outline-offset: 2px;
        }
      }
    }
    

    li {
      margin-bottom: 12px;
      line-height: 16px;

      a {
        font-size: variables.$text-size-sm;
        text-decoration: underline;
        color: variables.$site-footer-link-text-color;
      }
    }

    &.secondary {
      //We make this important as themed footers are supported by some products but we always want to use this alternative colour on the footer switch pages.
      background-color: variables.$site-footer-bg-color-secondary !important;

      p {
        &.h6 {
          color: variables.$site-footer-secondary-text-color;
        }
      }

      h6,
      p,
      svg,
      label {
        fill: variables.$site-footer-secondary-text-color;
        color: variables.$site-footer-secondary-text-color;

        g {
          fill: variables.$site-footer-secondary-text-color;
        }
      }

      li {

        a {
          color: variables.$site-footer-secondary-link-text-color;

          &:focus-visible {
            outline: 2px solid black !important;
            outline-offset: 2px;
          }
        }
      }

      .brand-logo {
        g {
          fill: variables.$secondary-color-signature-red;
        }
      }
    }
  }
}

@media only screen and (min-width: variables.$site-body-width-L) {
  .footer-tablet {
    display: none;
  }
}

@media only screen and (min-width: variables.$site-body-width-S) and (max-width:variables.$site-body-width-L) {
  .col-md-3 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

/*To handle when we browse the site on mobile screen*/
@media only screen and (max-width: variables.$site-body-width-S) {
  div {
    text-align: left;
  }

  .footer-tablet {
    display: none;
  }

  footer {
    text-align: left;
  }
}
/* Buttons */
@use '../3-helpers/variables';
@use '../3-helpers/mixins';


button:not(kendo-pager button):not(kendo-datapager button):not(kendo-datepicker button),
input[type="button"],
.button-default {

  &:not(.accordion-button) {

    .far,
    .fas {
      color: variables.$button-default-text-default-color;
    }

    @include mixins.button ($bgcolor: variables.$button-default-color,
      $hoverbgcolor: variables.$button-default-hover-color,
      $focusbgcolor: variables.$button-default-hover-color,
      $activebgcolor: variables.$button-default-active-color );

    &.prominent {
      @include mixins.button ($bgcolor: variables.$button-prominent-default-color,
        $hoverbgcolor: variables.$button-prominent-default-hover-color,
        $focusbgcolor: variables.$button-prominent-default-hover-color,
        $activebgcolor: variables.$button-prominent-active-color );
    }

    &.subtle {
      @include mixins.button ($textcolor: variables.$button-subtle-text-default-color,
        $bgcolor: variables.$button-subtle-default-color,
        $bordercolor: variables.$button-subtle-default-border-color,
        $hoverbgcolor: variables.$button-subtle-default-hover-color,
        $focusbgcolor: variables.$button-subtle-default-hover-color,
        $activebgcolor: variables.$button-subtle-active-color );
    }

    &.ghost {
      @include mixins.button ($textcolor: variables.$button-ghost-text-default-color,
        $hovertextcolor: variables.$button-ghost-text-hover-color,
        $activetextcolor: variables.$button-ghost-text-active-color,
        $focustextcolor: variables.$button-ghost-text-active-color,
        $bgcolor: variables.$button-ghost-default-color,
        $hoverbgcolor: variables.$transparent,
        $focusbgcolor: variables.$transparent,
        $activebgcolor: variables.$button-ghost-active-color,
        $buttonpadding: 12px 15px);
      text-decoration: underline;
    }

    &.spinner {
      display: flex;
      align-items: center;
      justify-content: center;

      .spinner-border {
        margin-left: 4px;
      }
    }

    &.pill {
      @include mixins.button ($textcolor: variables.$button-pill-text-default-color,
        $bgcolor: variables.$button-pill-default-color,
        $bordercolor: variables.$button-pill-default-border-color,
        $hoverbgcolor: variables.$button-pill-default-hover-color,
        $focusbgcolor: variables.$button-pill-default-hover-color,
        $activebgcolor: variables.$button-pill-active-color,
        $buttonpadding: variables.$button-pill-padding,
        $buttonradius: variables.$button-pill-radius );
    }

    @media only screen and (max-width: variables.$site-body-width-S) {
      //DO NOT REMOVE, THIS IS THE CODE WHICH ENSURES ALL SITE BUTTONS FOR MOBILE OCCUPY 100% WIDTH//
      width: 100%;

      &.navbar-toggler {
        width: auto;
      }
    }

    &.focus-visible-space-adjust {
      &:focus-visible {
        margin-left: 5px;
      }

    }

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}



.inverse-content-block,
.inverse {

  button,
  input[type="button"],
  .button-default {
    &:not(.accordion-button) {

      @include mixins.button ($textcolor: variables.$button-default-inverse-text-default-color,
        $hovertextcolor: variables.$button-default-inverse-text-default-color,
        $activetextcolor: variables.$button-default-inverse-text-default-color,
        $focustextcolor: variables.$button-default-inverse-text-default-color,
        $bgcolor: variables.$button-default-inverse-color,
        $hoverbgcolor: variables.$button-default-inverse-hover-color,
        $focusbgcolor: variables.$button-default-inverse-hover-color,
        $activebgcolor: variables.$button-default-inverse-active-color );

      &.prominent {
        @include mixins.button ($textcolor: variables.$button-prominent-inverse-text-color,
          $hovertextcolor: variables.$button-prominent-inverse-text-color,
          $focustextcolor: variables.$button-prominent-inverse-text-color,
          $activetextcolor: variables.$button-prominent-inverse-text-color,
          $bgcolor: variables.$button-default-inverse-color,
          $hoverbgcolor: variables.$button-prominent-inverse-hover-color,
          $focusbgcolor: variables.$button-prominent-inverse-hover-color,
          $activebgcolor: variables.$button-prominent-inverse-active-color );
      }

      &.subtle {
        @include mixins.button ($textcolor: variables.$button-subtle-inverse-text-default-color,
          $hovertextcolor: variables.$button-default-inverse-text-default-color,
          $activetextcolor: variables.$button-default-inverse-text-default-color,
          $focustextcolor: variables.$button-default-inverse-text-default-color,
          $bordercolor: variables.$button-subtle-inverse-default-border-color,
          $bgcolor: transparent,
          $hoverbgcolor: variables.$button-default-inverse-hover-color,
          $focusbgcolor: variables.$button-default-inverse-hover-color,
          $activebgcolor: variables.$button-default-inverse-active-color );
      }

      &.ghost {
        @include mixins.button ($textcolor: variables.$button-ghost-inverse-text-default-color,
          $hovertextcolor: variables.$button-ghost-inverse-text-default-hover-color,
          $focustextcolor: variables.$button-ghost-inverse-text-default-hover-color,
          $activetextcolor: variables.$button-ghost-inverse-text-default-active-color,
          $bgcolor: variables.$button-ghost-default-color,
          $hoverbgcolor: variables.$transparent,
          $focusbgcolor: variables.$transparent,
          $activebgcolor: variables.$button-ghost-inverse-default-active-color,
          $buttonpadding: 12px 15px);
        text-decoration: underline;
      }

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }
}

.ngb-dp-arrow,
.input-group-text {

  button,
  input[type="button"],
  .button-default {
    min-width: unset;
  }
}

.ngb-dp-arrow {

  button,
  input[type="button"],
  .button-default {
    padding: 0 .25rem;
  }
}

.button-sleeve {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  align-items: center;

  @media print {
      display: none;
  }

  //This covers cases where the buttons may not be the first child of the button-sleeve as they are wrapped in a div which needs to take 100% width for mobile//
  @media only screen and (max-width: variables.$site-body-width-S) {

    > div,
    app-aon-cta {
      width: 100%;
      text-align: center;
    }
  }

  app-aon-cta {
    &:not(:last-child) {

      button,
      a,
      input[type="button"] {
        @media only screen and (max-width: variables.$site-body-width-S) {
          margin-right: 0px;
        }

        margin-right: 20px;
      }
    }
  }

  button,
  a,
  input[type="button"] {

    &:not(:last-child) {
      @media only screen and (max-width: variables.$site-body-width-S) {
        margin-right: 0px;
      }

      margin-right: 20px;
    }
  }
}

.icon-button-sleeve {
  width: 20px;
}

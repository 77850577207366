//Literal - 1:1 relationship between the variable name and what it represents, ie variables which mention their colour in the name.
//Abstract - We use an abstract variable name as a wrapper, to point at a Literal one and our SCSS files refer to the abstract variable.
//This way, if a different font or colour is required site-wide it can just be switched here, nothing in the SCSS files should reference Literal variables


//LITERAL VARIABLES -- ONLY FOR USE IN THIS FILE, **DO NOT USE IN OTHER SCSS FILES, REFERENCE THEM VIA COMPONENT SPECIFIC VARIABLES**

//Fonts

$helvetica-now-text-regular: "Helvetica Now Text Regular", Roboto, Times, sans-serif;
$helvetica-now-text-light: "Helvetica Now Text Light", Roboto, Times, sans-serif;
$helvetica-now-text-bold: "Helvetica Now Text Bold", Roboto, Times, sans-serif;
$helvetica-now-display-bold: "Helvetica Now Display Bold", Roboto, Times, sans-serif;
$helvetica-now-display-regular: "Helvetica Now Display Regular", Roboto, Times, sans-serif;
$helvetica-now-display-light: "Helvetica Now Display Light", Roboto, Times, sans-serif;
$hans-sans: "HansSans", Roboto, Times, sans-serif;
$arial-unicode-ms: "Arial Unicode MS", Roboto, Times, sans-serif;
$noto-sans-devanagari: "NotoSansDevanagari", Roboto, Times, sans-serif;
$font-awesome: "Font Awesome 6 Pro";

:root {
  --main-font-bold: #{$helvetica-now-text-bold};
  --main-font-regular: #{$helvetica-now-text-regular};
  --main-font-light: #{$helvetica-now-text-light};
  --heading-font-bold: #{$helvetica-now-display-bold};
  --heading-font-regular: #{$helvetica-now-display-regular};
  --heading-font-light: #{$helvetica-now-display-light};
  --performance-best: #12A88A;
  --performance-good: #8ABD45;
  --performance-okay: #FFA600;
  --performance-bad: #F25D00;
  --performance-worst: #EA2238;
}

html[lang="ja-JP"] {
  --main-font-bold: "Noto Sans JP Thin Bold",
  #{$helvetica-now-text-bold};
  --main-font-regular: "Noto Sans JP Thin Regular",
  #{$helvetica-now-text-regular};
  --main-font-light: "Noto Sans JP Thin Light",
  #{$helvetica-now-text-light};
  --heading-font-bold: "Noto Sans JP Thin Bold",
  #{$helvetica-now-display-bold};
  --heading-font-regular: "Noto Sans JP Thin Regular",
  #{$helvetica-now-display-regular};
  --heading-font-light: "Noto Sans JP Thin Light",
  #{$helvetica-now-display-light};
}

//Colors
//Core & Secondary Hues
$selected-bg-color: #0096ff;
$core-color-blue: #012774;
$core-color-blue-lighter: #0138A7;
$core-color-blue-darker: #001D5F;
$core-color-marine: #007BB6;
$core-color-marine-lighter: #009DE9;
$core-color-marine-darker: #006a9c;
$secondary-color-navy: #001B5A;
$secondary-color-midnight: #011641;
$secondary-color-cobalt: #00519B;
$secondary-color-lagoon: #00A3C7;
$secondary-color-lagoon-lighter: #CBEEF6;
$transparent: transparent;
$box-shadow-color: rgba(192, 192, 192, .5);
$core-color-navy: #262836;
$core-color-teal-dark: #007585;
$core-color-teal-dark-hover: #004852;
$core-color-teal-dark-active: #001B1F;
$secondary-color-teal-light: #29B0C3;
$secondary-color-signature-red: #EB0017;
$secondary-color-hover-red: #B80012;
$secondary-color-peacock: #003C55;
$secondary-color-teal-light-alpha25: #29b0c340;

//Text Hues
$white: #FFFFFF;
$white-primary: rgba(255, 255, 255, 0.87);
$white-secondary: rgba(255, 255, 255, 0.6);
$text-color-gray01: #46535E;
$text-color-gray02: #5D6D78;

//Text Emphasis
$high-emphasis-primary-text-color: $text-color-gray01;
$low-emphasis-text-color: $text-color-gray02;

//Background & Separation Values
$bg-color-slate: #4C4D4F;
$bg-color-shadow: #C0C0C0;
$bg-color-smoke: #E4E4E4;
$bg-color-fog: #F2F2F2;
$bg-color-pearl: #F8F8F8;
$bg-color-orient: #005983;
$bg-color-gray03: #82939A;
$bg-color-gray04: #ACC0C4;
$bg-color-gray05: #CDDBDE;
$bg-color-gray06: #E5EFF0;
$bg-color-gray07: #EEF6F7;
$bg-color-gray08: #F9FCFC;
$bg-color-deSelect: #001B1F;
$bg-color-sky-lighter: #69CFFF;
$bg-color-sky-lightest: #CFF0FF;

//Data Visualisation Colors
$dv-cobalt-hover: #003B75;
$dv-green-active: #084C3E;
$dv-red-active: #980E1D;
$dv-orange-active: #8C3600;
$bg-dv-marine: #E5F2F8;
$bg-dv-aqua: #73E2D8;
$bg-error-light: #FCDCE0;
$bg-warning-light: #FFEDCC;

//Inverse Colors

//Link Hues
$link-color-sky: #9CDFFF;
$link-color-sky-lighter: #CFF0FF;
$link-color-sky-darker: #69CFFF;
$link-color-frost: #CFF0FF;
$link-color-frost-lighter: #9CE0FF;
$link-color-frost-darker: #69D0FF;


//END LITERAL VARIABLES


//START ABSTRACTED VARIABLES -- **THESE ARE THE ONES WE SHOULD BE USING FROM OUR SCSS FILES**

//HTML
$site-canvas-color: $bg-color-slate;

//PAGE BACKGROUND
$page-background-color: $bg-color-gray08;

//Containers
$inverse-container-color: $core-color-navy;

//Scaffolding
$site-body-width-XXL: 1600px;
$site-body-width-XL: 1200px;
$site-body-width-L: 992px;
$site-body-width-M: 768px;
$site-body-width-S: 576px;
$site-body-width-XS: 320px;
$small_screen_boundary: 576px;

$product-area-width: 1600px;

//Header
$site-header-bg-color: $white;
$site-header-height: 64px;
$site-header-height-mobile: 89px;
$site-header-link-hover-color: $secondary-color-hover-red;
$site-header-link-active-color: $secondary-color-signature-red;


//Mobile Nav Menu
$site-mobile-nav-menu-text-color-main: $white;
$site-mobile-nav-menu-controls-text-color: #9CDFFF;
$site-mobile-nav-menu-controls-text-color-collapsed: $core-color-teal-dark;

//Footer
$site-footer-bg-color: $core-color-navy;
$site-footer-bg-color-secondary: $bg-color-pearl;
$site-footer-main-text-color: $bg-color-gray06;
$site-footer-main-text-color-two: $bg-color-gray04;
$site-footer-link-text-color: $bg-color-gray06;
$site-footer-secondary-text-color: $low-emphasis-text-color;
$site-footer-secondary-link-text-color: $core-color-teal-dark;

//Login page
$site-login-icon-color: $white;
$site-login-input-border-color: $white;


//Fonts
$heading-font-bold: var(--heading-font-bold);
$heading-font-regular: var(--heading-font-regular);
$heading-font-light: var(--heading-font-light);
$main-font-regular: var(--main-font-regular);
$main-font-light: var(--main-font-light);
$main-font-bold: var(--main-font-bold);


//Typography

//Headers
$header-color-one: $core-color-navy;
$header-color-two: $high-emphasis-primary-text-color;
$header-color-three: $low-emphasis-text-color;
$header-color-four: $core-color-teal-dark;
$header-inverse-color: $bg-color-gray06;
$header-inverse-color-two: $bg-color-gray04;



//Font Weight Helpers, you'll need to know whether the font supports variable weighting when deciding whether to reference these from your scss files.
//If your font does not support variable weighting then use the applicable font variable itself.

//Font-weights - Use these if your font supports variable weighting
$main-font-weight-light: 300;
$main-font-weight-normal: 400;
$main-font-weight-bold: 700;

//Font-sizes - baseline values used by shorthand helper classes as well as P declarations
$text-size-xs: 12px;
$text-size-sm: 14px;
$text-size-lg: 18px;
$text-size-xl: 21px;

//Paragraphs
$paragraph-color-one: $high-emphasis-primary-text-color;
$paragraph-color-two: $low-emphasis-text-color;
$paragraph-inverse-color-one: $bg-color-gray06;
$paragraph-inverse-color-two: $bg-color-gray04;
$paragraph-color-three: $core-color-teal-dark;
$paragraph-color-four: $core-color-navy;

//Line heights for paragraphs
$line-height-xs: 16px;
$line-height-sm: 18px;
$line-height-lg: 24px;
$line-height-xl: 28px;


//Links
$link-default-color: $core-color-teal-dark;
$link-hover-color: $core-color-teal-dark-hover;
$link-active-color: $core-color-teal-dark-active;
$link-inverse-default-color: $bg-color-gray06;
$link-inverse-hover-color: $bg-color-gray06;
$link-inverse-active-color: $bg-color-gray06;
$link-inverse-default-color-two: $link-color-frost;
$link-inverse-hover-color-two: $link-color-frost-lighter;
$link-inverse-active-color-two: $link-color-frost-darker;
$link-color-two : $core-color-blue;
$link-color-three: $core-color-navy;
$link-color-four: $low-emphasis-text-color;

//Lists
$list-default-margin-bottom: 16px;

//Alerts
$alert-banner-text-color: $paragraph-color-four;
$alert-contextual-text-color: $paragraph-color-four;

//Feedback Hues
$feedback-color-error: #EA2238;
$feedback-color-error-darker: #CA110F;
$feedback-color-error-lighter: #FCDCDC;
$feedback-color-error-inverse: #F46665;
$feedback-color-warning: #FFA600;
$feedback-color-warning-darker: #996300;
$feedback-color-warning-lighter: #FFF6B7;
$feedback-color-success: #12A88A;
$feedback-color-success-darker: #0D725E;
$feedback-color-success-lighter: #C1F2E9;
$feedback-color-info: $core-color-teal-dark;
$feedback-color-info-lighter: #CCEAF5;
$feedback-color-info-darker: #003B75;



//Expansion Panels
$expansion-panel-bg-color: transparent;
$expansion-panel-header-color: $core-color-teal-dark;
$expansion-panel-header-expanded-color: $core-color-teal-dark-active;
$expansion-panel-header-hover-color: $core-color-teal-dark-hover;
$expansion-panel-text-color: $high-emphasis-primary-text-color;
$expansion-panel-border-top-color: $bg-color-gray05;
$expansion-panel-border-bottom-color: $bg-color-gray05;
$expansion-panel-header-color-inverse: $bg-color-gray06;
$expansion-panel-text-color-inverse: $bg-color-gray06;


//Cards
$card-bg-color: $white;
$card-border-color: $bg-color-gray04;
$card-edit-state-bg-color: $bg-color-gray08;

//Password Rules
$password-rules-bg-color: $bg-color-gray06;
$password-rules-bg-color-inverse: $text-color-gray01;
$password-rules-icon-color: $low-emphasis-text-color;
$password-rules-inverse-icon-color: $white;
$password-rules-icon-failure-color: $feedback-color-error;
$password-rules-icon-success-color: $feedback-color-success;
$password-rules-icon-success-inverse-color: $feedback-color-success;

//Buttons

$button-default-text-default-color: $white;
$button-default-color: $core-color-teal-dark;
$button-default-hover-color: $core-color-teal-dark-hover;
$button-default-active-color: $core-color-teal-dark-active;
$button-default-radius: 4px;

$button-prominent-default-color: $core-color-navy;
$button-prominent-default-hover-color: $text-color-gray01;
$button-prominent-active-color: $bg-color-gray03;

$button-subtle-text-default-color: $core-color-navy;
$button-subtle-default-color: $transparent;
$button-subtle-default-border-color: $core-color-navy;
$button-subtle-default-hover-color: $text-color-gray01;
$button-subtle-active-color: $bg-color-gray03;

$button-ghost-text-default-color: $core-color-teal-dark;
$button-ghost-text-hover-color: $core-color-teal-dark-hover;
$button-ghost-text-active-color: $core-color-teal-dark-active;
$button-ghost-default-color: $transparent;
$button-ghost-default-hover-text-color: $core-color-teal-dark;
$button-ghost-default-border-color: $white;
$button-ghost-active-color: rgba(228, 228, 228, 0.5);
$button-ghost-padding: 12px 15px;


$button-pill-text-default-color: $core-color-teal-dark;
$button-pill-default-color: $white;
$button-pill-default-border-color: $core-color-teal-dark;
$button-pill-default-hover-color: $core-color-teal-dark;
$button-pill-active-color: $core-color-teal-dark;
$button-pill-radius: 18px;
$button-pill-padding: 8px 16px;

$dark-mode-inverse-focus-ring-color: $white;

//Inverse Buttons

$button-default-inverse-text-default-color: $core-color-navy;
$button-default-inverse-color: $bg-color-gray06;
$button-default-inverse-hover-color: $bg-color-gray06;
$button-default-inverse-active-color: $bg-color-gray06;

$button-prominent-inverse-text-color: $secondary-color-navy;
$button-prominent-inverse-hover-color: $bg-color-sky-lightest;
$button-prominent-inverse-active-color: $bg-color-sky-lighter;

$button-subtle-inverse-text-default-color: $link-color-frost;
$button-subtle-inverse-default-border-color: $link-color-frost;

$button-ghost-inverse-text-default-color: $link-color-sky;
$button-ghost-inverse-text-default-hover-color: $link-color-sky-lighter;
$button-ghost-inverse-text-default-active-color: $link-color-sky-darker;
$button-ghost-inverse-default-hover-color: rgba(233, 248, 255, 0.3);
$button-ghost-inverse-default-active-color: rgba(233, 248, 255, 0.5);


//Checkboxes
$checkbox-border-color: $high-emphasis-primary-text-color;
$checkbox-border-hover-color: $core-color-teal-dark-hover;
$checkbox-border-checked-color: $core-color-teal-dark;
$checkbox-bg-checked-color: $core-color-teal-dark;
$checkbox-border-inverse-color: $bg-color-gray06;
$checkbox-focused-outline-color: $core-color-teal-dark;
$checkbox-focused-outline-inverse-color: $bg-color-gray06;

//Radio Buttons
$radio-border-color: $high-emphasis-primary-text-color;
$radio-border-hover-color: $core-color-teal-dark;
$radio-border-checked-color: $core-color-teal-dark;
$radio-bg-checked-color: $core-color-teal-dark;
$radio-container-border-color-unselected: $bg-color-gray04;
$radio-container-border-color-selected: $core-color-teal-dark;
$radio-focused-outline-color: $core-color-teal-dark;
$radio-focused-outline-inverse-color: $bg-color-gray06;

//TextArea
$textarea-border-color: $high-emphasis-primary-text-color;
$textarea-border-hover-color: $core-color-teal-dark;
$textarea-text-color: $high-emphasis-primary-text-color;
$textarea-bg-color: $white;
$textarea-disabled-bg-color: $white;

//TextInput
$textinput-border-color: $high-emphasis-primary-text-color;
$textinput-border-hover-color: $core-color-teal-dark-hover;
$textinput-border-focus-color: $core-color-teal-dark;
$textinput-text-color: $high-emphasis-primary-text-color;
$textinput-bg-color: $transparent;
$textinput-disabled-bg-color: $white;

$textinput-border-color-inverse: $bg-color-gray06;
$textinput-text-color-inverse: $bg-color-gray06;
$textinput-icon-color-inverse: $bg-color-gray06;
$textinput-border-focus-color-inverse: $secondary-color-teal-light;

//Selects
$select-border-color: $high-emphasis-primary-text-color;
$select-border-color-closed: $core-color-teal-dark;
$select-popup-border-color: $core-color-teal-dark-active;
$select-text-color: $high-emphasis-primary-text-color;
$select-icon-fill-color: $high-emphasis-primary-text-color;
$select-icon-fill-color-inverse: $bg-color-gray06;
$select-bg-color: $white;
$select-option-hover-bg-color: $bg-color-smoke;
$select-option-bg-color: $core-color-teal-dark-active;
$select-option-text-color: $white;
$select-border-color-inverse: $bg-color-gray06;
$select-text-color-inverse: $bg-color-gray06;

//Forms
$form-group-margin-bottom: 32px;
$read-only-value-margin-bottom: 20px;

//Form labels
$label-text-color: $high-emphasis-primary-text-color;
$label-text-color-inverse: $bg-color-gray06;
$label-margin-bottom: 4px;

//Input content
$input-content-text-color: $high-emphasis-primary-text-color;
$input-content-text-color-inverse: $bg-color-gray06;

//Input description
$input-desc-text-color: $high-emphasis-primary-text-color;
$input-desc-text-color-inverse: $bg-color-gray04;

//Input Title
$input-title-text-color: $high-emphasis-primary-text-color;
$input-title-text-color-inverse: $bg-color-gray06;


//Spacing
$spacing_elements: 20px;
$spacing_related_content_min: 20px;
$spacing_related_content_max: 40px;
$spacing_distinct_content_min: 40px;

//Icons
$icon-default-color: $core-color-teal-dark;
$icon-default-size: 20px;
$icon-form-inputs-default-size: 16px;
$icon-inverse-color: $bg-color-gray06;
$modal-input-icon-color: $core-color-teal-dark-active;
$icon-color-one: $core-color-navy;
$icon-color-two: $high-emphasis-primary-text-color;
$icon-color-three: $low-emphasis-text-color;

//Data-Metric text
$data-metric-main-color: $high-emphasis-primary-text-color;
$data-metric-cost-color: $core-color-navy;

//Dividers
$tbs-divider-color: $bg-color-gray05;

//Data Table
$tbs-table-row-border-color: $bg-color-gray05;
$tbs-table-row-background: $bg-color-gray08;
$tbs-table-cell-padding-y: 16px;
$tbs-table-cell-padding-x: 16px;
$tbs-table-data-font-size: 14px;

//Breadcrumb
$breadcrumb-main-color: $core-color-navy;

//Tab link
$tab-link-color: $low-emphasis-text-color;
$tab-link-current-color: $secondary-color-peacock;
$tab-border-color: $bg-color-gray05;

//Masthead
$masthead-dropdown-bg-color: $bg-color-gray07;

//Scrollbar
$scrollbar-thumb-color: $low-emphasis-text-color;

//Tag text and background color
$tag-teal-text-color: $dv-cobalt-hover;
$tag-teal-bg-color: $feedback-color-info-lighter;
$tag-green-text-color: $dv-green-active;
$tag-green-bg-color: rgba($bg-dv-aqua, 0.3);
$tag-red-text-color: $dv-red-active;
$tag-red-bg-color: rgba($bg-error-light, 0.8);
$tag-yellow-text-color: $dv-orange-active;
$tag-yellow-bg-color: rgba($bg-warning-light, 0.8);
$tag-gray-text-color: $core-color-navy;
$tag-gray-bg-color: rgba($bg-color-gray06, 0.8);

//Inverse border color
$border-color-inverse: $bg-color-gray06;

//Document expansion background color
$document-expanded-bg-color: $bg-color-gray07;

//Cost Label
$cost-label-color: $low-emphasis-text-color;

//Popovers
$popover-header-bg-color: $bg-color-gray08;

//Form Controls
$form-control-max-width: 280px;

//Delegate navigation
$masthead-delegate-bg-color: $text-color-gray01;
$masthead-delegate-text-color: $bg-color-gray06;
$masthead-delegate-button-hover: $text-color-gray02;
$masthead-delegate-button-hover-border: $bg-color-gray08;

//END ABSTRACTED VARIABLES
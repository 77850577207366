@use '../3-helpers/variables';
@use '../3-helpers/mixins';


.checkbox-wrapper {
  margin-bottom: 20px;
  min-height: 24px;
  font-family: variables.$main-font-regular;
}

.n-mt-32 {
  margin-top: 32px;
}

.n-lh-28,
h2.n-lh-28 {
  line-height: 28px;
}

button.n-btn-width {
  width: auto;
  min-width: 130px;
  height: 44px;
}

input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

input[type="checkbox"]+label {
  font-family: variables.$main-font-regular;
  font-weight: variables.$main-font-weight-normal;
  position: relative;
  top: 0;
  padding-left: 34px;
  padding-right: 10px;
  cursor: pointer;
  line-height: 20px;
  font-size: 16px;
  display: block;
}


input[type=checkbox]+label p {
  line-height: 24px;
}

input[type="checkbox"]+label::before,
input[type="checkbox"]+label::after {
  position: absolute;
  display: inline-block;
}

input[type="checkbox"]+label::before {
  box-sizing: border-box;
  content: "";
  min-height: 24px;
  border-radius: 4px;
  width: 24px;
  border: 1px solid variables.$checkbox-border-color;
  left: 0;
}

.inverse input[type="checkbox"]+label::before {
  border: 1px solid variables.$checkbox-border-inverse-color;
}

input[type="checkbox"]+label:hover::before {
  box-sizing: border-box;
  border: 2px solid variables.$checkbox-border-hover-color;
}

.inverse input[type="checkbox"]+label:hover::before {
  box-sizing: border-box;
  border: 2px solid variables.$checkbox-border-inverse-color;
}

input[type="checkbox"]+label::after {
  left: 5px;
  top: 2px;
  content: none;
}

input[type="checkbox"]:checked+label::after {
  content: "\f00c";
  font-family: variables.$font-awesome;
  color: variables.$white;
}

input[type="checkbox"]:checked+label::before {
  box-sizing: border-box;
  content: "";
  border: 1px solid variables.$checkbox-border-checked-color;
  background-color: variables.$checkbox-bg-checked-color;
}

.inverse input[type="checkbox"]:checked+label::before {
  box-sizing: border-box;
  content: "";
  border: 1px solid variables.$checkbox-border-inverse-color;
}

/* ensure checkbox has focus to enable accessibility*/
input[type="checkbox"]:focus+label::before {
  outline: variables.$checkbox-focused-outline-color auto 2px;
}

input[type=checkbox]:focus:checked+label::before {
  outline: black auto 2px;
}

.inverse input[type="checkbox"]:focus+label::before {
  outline: variables.$checkbox-focused-outline-inverse-color auto 2px;
}

/** disabled state */

input[type="checkbox"]:disabled+label {
  cursor: default;
}

input[type="checkbox"]:disabled+label::before,
input[type="checkbox"]:disabled+label::after {
  cursor: not-allowed;
}

input[type="checkbox"]:disabled+label::before {
  opacity: 0.5;
}

.inverse input[type="checkbox"]:disabled+label::before {
  opacity: 1;
}

.inverse input[type="checkbox"]:disabled+label:hover::before {
  border: 1px solid variables.$checkbox-border-inverse-color !important;
}

/** error state */
input[type="checkbox"].error {
  color: variables.$feedback-color-error;
}

.error label:hover::before {
  box-sizing: border-box;
  border: 2px solid variables.$feedback-color-error;
}

.error input[type="checkbox"]+label::before {
  content: "";
  border-color: variables.$feedback-color-error;
}


.error input[type="checkbox"]:checked+label::before {
  content: "";
  border: none;
  background-color: variables.$feedback-color-error;
}
@use '../3-helpers/variables';


.aon-icon {

  &::before {
    font-family: variables.$font-awesome;
    font-weight: variables.$main-font-weight-light;
    font-size: variables.$icon-default-size;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }


  &.aon-help::before {
    content: "\f1cd";
  }

  &.aon-times::before {
    content: "\f00d";
  }

  &.aon-bars::before {
    content: "\f0c9";
  }

  &.aon-menu-collapse::before {
    content: "\f107";
  }

  @media only screen and (max-width: variables.$site-body-width-L) {
    &.aon-menu-collapse::before {
      content: "\f078";
    }
  }

  &.aon-angle-up::before {
    content: "\f106";
  }

  @media only screen and (max-width: variables.$site-body-width-L) {
    &.aon-angle-up::before {
      content: "\f077";
    }
  }

  &.aon-angle-down::before {
    content: "\f078";
  }

  &.aon-user::before {
    content: "\f007";
  }

  &.aon-arrow-left::before {
    content: "\f060";
  }

  &.aon-cart::before {
    content: "\f07a";
  }

  &.aon-message::before {
    content: "\f0e0";
  }

  &.aon-deep-link::before {
    content: "\f08e";
  }

  &.aon-edit::before {
    content: "\f044";
  }

  &.aon-delete::before {
    content: "\f2ed";
  }

  &.aon-check::before {
    content: "\f00c";
  }

  &.aon-chat::before {
    content: "\f4b6";
  }

  &.aon-coins::before {
    content: "\f51e";
  }

  &.aon-money-bill::before {
    content: "\f0d6";
  }

  &.aon-money-bills::before {
    content: "\e1f4";
  }

  &.aon-money-bill-wave::before {
    content: "\f53a";
  }

  &.aon-credit-card::before {
    content: "\f09d";
  }

  &.aon-wallet::before {
    content: "\f555";
  }

  &.aon-sterling::before {
    content: "\f154";
  }

  &.aon-dollar::before {
    content: "\24";
  }

  &.aon-euro::before {
    content: "\f153";
  }

  &.aon-qrcode::before {
    content: "\f029";
  }
}

.form-group {
  .aon-icon {

    &::before {
      font-size: variables.$icon-form-inputs-default-size;
    }
  }
}

//Treatment for icons in buttons, it is expected these will always be to the left of the label (spinners are the exception and are handled differently)
//Therefore we add a margin-right of 10px as standard.
button,
.button-default {
  &.aon-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      margin-right: 10px;
      font-size: variables.$icon-form-inputs-default-size;
    }
  }
}

.aon-icon-info {
  cursor: pointer;

  &::before {
    content: "\f05a";
    font-family: variables.$font-awesome;
    font-weight: variables.$main-font-weight-light;
  }
}

.tooltip .tooltip-inner {
  background-color: variables.$bg-color-slate;
}

.tooltip .arrow::before {
  border-top-color: variables.$bg-color-slate;
}

@use '../../global/3-helpers/variables' as variables;

.k-pager {
    justify-content: center;
    background-color: variables.$white;
    padding: 20px;

    .k-button-flat-primary {
        color: unset;
    }

    .k-button {
        color: variables.$white;
        background-color: variables.$bg-color-gray03 !important;
    }

    .k-pager-info {
        text-align: unset;
        justify-content: unset;
        flex: unset;
    }
}

.k-pager-md {
    @media only screen and (max-width: variables.$site-body-width-S) {
        gap: unset !important;
    }
}
@use '../3-helpers/variables.scss';

kendo-multiselect {
    &.k-multiselect {
        flex-direction: column-reverse;

        @media only screen and (min-width: variables.$site-body-width-S) {
            max-width: variables.$form-control-max-width;
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
            width: 100%;
        }

        .k-input-values {
            padding-top: 0px;
        }

        .k-input-values>.k-searchbar {
            margin-top: 0px;
        }

        .k-searchbar {
            width: 100%;
        }

        .k-clear-value {
            width: 100%;
            justify-content: flex-end;
        }
    }
}
@use '../3-helpers/variables' as variables;

@mixin aon-icon($icon,
  $display: inline-block,
  $fontfamily: variables.$fa-fontfamily-pro,
  $textrendering: auto,
  $fontstyle: normal,
  $font-size: 20px,
  $font-variant: normal,
  $fontweight: variables.$fa-solid-weight,
  $lineheight: 1) {
  display: $display;
  font-family: $fontfamily;
  text-rendering: $textrendering;
  font-style: $fontstyle;
  font-size: $font-size;
  font-weight: $fontweight;
  line-height: $lineheight;
  font-variant: $font-variant;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    font-family: $fontfamily !important;
    font-weight: $fontweight !important;
    content: $icon;
  }
}
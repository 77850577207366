@use '../3-helpers/variables';

@mixin loadFontFamily($fontFamily) {
  kendo-pdf-document {
    *:not(.pdf-currency-font) {
      font-family: $fontFamily !important;
    }

    .italic {
      font-style: normal !important;
    }

    h6,
    h5,
    h4,
    h3,
    h2,
    h1,
    .h6,
    .h5,
    .h4,
    .h3,
    .h2,
    .h1,
    p.input_label {
      font-weight: variables.$main-font-weight-normal !important;
    }
  }
}

html[lang="zh-CN"],
html[lang="zh-MO"],
html[lang="zh-HK"],
html[lang="ja-JP"] {
  @include loadFontFamily(variables.$hans-sans);
}

html[lang="hi-IN"] {
  @include loadFontFamily(variables.$noto-sans-devanagari);
}

.hidden-content-wrapper {
  display: none;
}

kendo-pdf-document {
  .kendo-pdf-no-basic-card {
    padding: 0px !important;
    border: 0px !important;
  }

  .alert.banner {
    text-underline-offset: 3px;
  }

  .hidden-content-wrapper {
    display: block;
  }

  .no-export {
    display: none;
  }

  .kendo-pdf-no-margin {
    margin: 0px !important;
  }

  .onlyPdfExport {
    display: block !important;

    h3 {
      sup {
        padding-left: 10px !important;
      }
    }
  }

  .cls-trs-based-data-summary-left-content {
    .kendo-chart-size {
      transform: scale(0.55);
      position: relative;
      left: -35px;
      margin-bottom: 80px;
    }
  }

  h1,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .strong {
    font-weight: variables.$main-font-weight-normal;
  }

  app-sable-dataview-chart {
    .kendo-chart-size {
      margin-bottom: 60px;
    }
  }

  .dashboard-overview {
    .kendo-chart-size {
      transform: scale(0.55);
      position: relative;
      top: -40px;
      left: -85px;
    }
  }

  .pdf-allow-apostrophe {
    font-weight: unset !important;
  }

  .pdf-currency-font {

    // Overriding Font Styles to fix unicode char issues for thai baht
    &.pdf-font-3647 {
      font-family: variables.$arial-unicode-ms !important;
      font-weight: variables.$main-font-weight-normal !important;
      font-style: normal !important;
    }
  }

  #active-tab-for-print {
    font-weight: variables.$main-font-weight-normal !important;
    font-family: variables.$main-font-bold;
  }

  .accordion,
  .custom-accordion {
    .expansion-panel {
      border-top: 0px !important;
      border-bottom: 0px !important;
    }
  }

  .page {
    background-color: white !important;
  }

  .kendo-no-left-right-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .kendo-pdf-w-100 {
    width: 100% !important;
  }

  app-aon-cta,
  button:not(.accordion-button),
  .button-default {
    display: none !important;
  }

  @media only screen and (min-width: variables.$site-body-width-XL) {
    #home-hero {
      .product-intro-text {
        padding-left: 80px !important;
      }
    }
  }
}

aon-input-date {
  kendo-datepicker {
    kendo-dateinput {
      input {
        z-index: auto !important;
      }
    }

    @media only screen and (min-width: variables.$site-body-width-S) {
      max-width: variables.$form-control-max-width;
    }
  }
}

.hide-when-no-export {
  display: none;
}


kendo-fileselect {
  &:active {
    border: none;
  }

  padding: 0px !important;
  cursor: default !important;
  width: 100%;

  .k-dropzone {

    &.k-upload-dropzone {
      display: inline-flex !important;
      border-color: unset !important;
      background-color: unset !important;
    }
  }

  .k-upload-files {
    &.ng-star-inserted {
      margin-top: 8px;
    }
  }

  .k-button-solid-base {
    color: variables.$white !important;
  }

  kendo-upload-file-list-single-item {
    .k-file-info {
      text-align: start;
    }

    kendo-upload-file-list-item-action-button {
      button {
        min-width: unset !important;
        padding: 0px !important;
        border: none !important;
        background-color: transparent !important;

        >i.fa-times::before {
          content: "\f2ed";
        }
      }
    }
  }
}

.k-pdf-export {

  #hiddenPdf {
    p {
      span {
        font-size: 22px !important;
      }
    }

  }
}
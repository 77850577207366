@use '../3-helpers/variables';

.accordion,
.custom-accordion {
  /* SET PANEL BORDERS TO 0PX, GIVE ALL PANELS A BORDER TOP, AND THE LAST ONE A BORDER BOTTOM */

  //overwriting bootstrap variables value
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-btn-padding-y: 0;
  --bs-accordion-border-width: 0;
  --bs-accordion-border-radius: 0;

  .expansion-panel {
    background-color: variables.$expansion-panel-bg-color;
    border: 0px;
    border-top: 1px solid variables.$expansion-panel-border-top-color;
    padding: 16px 0px 16px 0px;

    &:last-child {
      border-bottom: 1px solid variables.$expansion-panel-border-bottom-color;
    }

    @media screen and (max-width: variables.$site-body-width-S) {
      padding: 10px 0px 10px 0px;
    }

    &.accordion-item {
      border-radius: 0px;

      .accordion-header {
        color: variables.$expansion-panel-header-color;
        border: 0px;
        padding: 0px;

        &:hover {
          cursor: pointer;
        }

        .header-sleeve {
          display: flex;
          align-items: center;
          background: none;

          &:focus {
            background-color: inherit;
            border-color: inherit;
            box-shadow: none;
          }

          i {
            font-size: 20px;
          }

          .header-text {
            color: variables.$expansion-panel-header-color;
            flex-grow: 1;
            font-family: variables.$main-font-bold;
            font-size: 20px;
            line-height: 24px;
          }


          &:hover {
            background-color: inherit;
            border-color: inherit;
            box-shadow: none;

            .header-text,
            .header-controls {
              color: variables.$expansion-panel-header-hover-color;
            }
          }

          &:not(.collapsed) {
            padding-bottom: 16px;

            .header-text,
            .header-controls {
              color: variables.$expansion-panel-header-expanded-color;
            }

            &:hover {

              .header-text,
              .header-controls {
                color: variables.$expansion-panel-header-hover-color;
              }
            }
          }
        }

        .accordion-button {
          border-radius: 0;

          &::after {
            content: none;
          }

          &:hover,
          &:focus {
            z-index: unset;
          }
        }
      }

      .card-body {
        padding: 0px;
        color: variables.$expansion-panel-text-color;

        p.expansion-panel-text {
          margin: 0px;
        }

        div.expansion-panel-content {
          color: variables.$expansion-panel-text-color;
          margin-top: 20px;

          p:last-child {
            padding-bottom: 0px;
            margin-bottom: 0px;
          }
        }
      }

      .accordion-body {
        padding: 0px;
        color: variables.$expansion-panel-text-color;

        p.expansion-panel-text {
          margin: 0px;
        }
      }
    }
  }

  .focus-visible-space-adjust {
    &:focus-visible {
      outline-offset: -2px !important;
      padding: 0 4px;
    }
  }

  @media screen and (max-width: variables.$site-body-width-S) {
    .expansion-panel.accordion-item .accordion-header .header-sleeve .header-text {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
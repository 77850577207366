@use '../3-helpers/variables';
@use '../3-helpers/mixins';

sable-dataview,
.sable-dataview {

  .sable-value:empty,
  .sable-label:empty {
    display: none !important;
  }

  .fal,
  .far {
    font-family: variables.$font-awesome !important;
  }

  .sable-table {
    .sable-header-cell.with-desc {
      cursor: pointer;
      text-decoration-line: underline;
      text-decoration-style: dashed;
      text-underline-position: under;
      -moz-text-decoration-line: underline;
      -moz-text-decoration-style: dashed;
      -webkit-text-decoration-line: underline;
      -webkit-text-decoration-style: dashed;
    }

    .sable-tbody {
      th {
        border-bottom: none;
      }
    }
  }

  .sable-label-value-sleeve {
    flex-grow: 1;
    justify-content: space-between;
    flex-direction: row;
  }

  lib-dataview-input-label {
    .data-metric-m {
      p.sable-value {
        @include mixins.data-metric-m;
      }
    }
  }
}


app-sable-dataview-table {
  .sable-table {
    .sable-tbody {
      .sable-body-row {
        .sable-header-cell {
          border-bottom: none !important;

          span {
            white-space: pre-line;
            top: 50px;
            left: 15px;
          }
        }
      }
    }
  }
}
@use "../3-helpers/variables.scss";
@use "../3-helpers/mixins";
@use "form-inputs";

//Universal Sable styles

.sable-flow,
sable-flow {
  /*THIS IS A DEDICATED SABLE DIVIDER CLASS ADDED BY SABLE TO DIVIDE BUTTONS FROM CONTENT - DO NOT USE THIS CLASS ELSEWHERE*/
  .sable-flow-divider {
    height: 1px;
    background-color: variables.$tbs-divider-color;
  }

  .bg-dark {
    background-color: transparent !important;
    padding: 0px;

    .container {
      padding: 0px;
    }
  }

  .container {
    padding: 0px;

    .card {
      background: variables.$card-bg-color;
      border: 1px solid variables.$card-border-color;
      border-radius: 8px;
      padding: 32px;
      width: 100%;

      &.expansion-panel {
        box-shadow: none;
        border: 0;
        border-top: 1px solid variables.$bg-color-shadow;
        padding: 12px 0;

        &:last-child {
          border-bottom: 1px solid variables.$bg-color-shadow;
        }
      }


      @media screen and (max-width: variables.$site-body-width-S) {
        padding: 20px;
        width: auto;
        border-radius: 0px;
      }

      .card-body {
        padding: 0px;

        .sable-form,
        sable-form {
          .aon-static-content {
            &.aon-label {
              @extend label;
            }

            &.aon-value {
              margin-bottom: variables.$read-only-value-margin-bottom;
              @extend .input-content;
            }

            &.aon-info-text {
              font-size: 12px;
              color: variables.$label-text-color;
              line-height: 16px;
            }
          }

          sable-input-selector {
            .aon-form-component {
              position: relative;

              .aon-icon-edit::before {
                content: "\f044";
                font-family: variables.$font-awesome;
                position: absolute;
                top: -2px;
                right: -15px;
                font-weight: variables.$main-font-weight-light;
                color: variables.$link-default-color;
                cursor: pointer;
                font-size: 18px;
              }

              .aon-link-primary {
                .aon-icon-view::before {
                  display: inline-block;
                  content: "\f002";
                  font-family: variables.$font-awesome;
                  font-weight: variables.$main-font-weight-normal;
                  margin-right: 8px;
                  cursor: pointer;
                }
              }
            }
          }

          .aon-form-component {
            margin-bottom: variables.$form-group-margin-bottom;

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            .input-group {
              .aon-form-input {
                position: relative;
                flex: 1 1 auto;
                width: 1%;
                min-width: 0;
                margin-bottom: 0;
              }
            }

            .aon-required,
            .aon-optional {
              font-family: variables.$main-font-regular;
              font-style: italic;
              font-weight: variables.$main-font-weight-light;
              line-height: 24px;
              color: variables.$input-desc-text-color;
            }

            &.aon-form-input-invalid {
              .aon-form-input {
                border: 2px solid variables.$feedback-color-error;
              }

              .aon-form-label {
                color: variables.$feedback-color-error;
              }

              input[type="radio"] + label::before {
                border-color: variables.$feedback-color-error;
              }
            }

            .aon-alert {
              &.aon-contextual-alert {
                @media only screen and (min-width: variables.$site-body-width-S) {
                  max-width: variables.$form-control-max-width;
                }

                display: flex;
                align-items: center;
                background-color: variables.$feedback-color-error-lighter;

                i {
                  color: variables.$feedback-color-error-darker;
                  font-size: 20px;
                  margin-right: 10px;

                  &::before {
                    content: "\f071";
                    font-family: variables.$font-awesome;
                    font-style: normal;
                    font-weight: variables.$main-font-weight-bold;
                  }
                }
              }
            }
          }

          .sable-input-radio,
          sable-input-radio {
            .aon-form-component {
              .aon-form-radio-group {
                .aon-form-radio {
                  .aon-form-radio-label {
                    display: flex;
                    align-items: flex-start;

                    &::before {
                      flex-shrink: 0;
                    }

                    P {
                      margin: 0px !important;

                      &.vet-address {
                        font-size: variables.$text-size-sm;
                        line-height: 18px;
                        color: variables.$paragraph-color-two;
                      }
                    }
                  }
                }
              }

              &.aon-border {
                &.aon-direction-row {
                  .aon-form-radio-group {
                    display: flex;
                    flex-direction: row;

                    .aon-form-radio {
                      margin-right: 20px;

                      &:last-child {
                        margin-right: 0px;
                      }

                      border: 1px solid variables.$radio-container-border-color-unselected;
                      border-radius: 12px;
                      padding: 20px;
                      min-width: 150px;

                      &.selected {
                        border: 2px solid variables.$radio-container-border-color-selected;
                        padding: 19px;
                      }

                      &.aon-icon-dog {
                        .aon-form-radio-label {
                          span {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            &::after {
                              content: url("/assets/images/form-controls/quote-dog-icon.svg");
                              background-repeat: no-repeat;
                              margin-top: 6px;
                              margin-bottom: 8px;
                            }
                          }
                        }
                      }

                      &.aon-icon-cat {
                        .aon-form-radio-label {
                          span {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            &::after {
                              content: url("/assets/images/form-controls/quote-cat-icon.svg");
                              background-repeat: no-repeat;
                              margin-top: 6px;
                              margin-bottom: 8px;
                            }
                          }
                        }
                      }

                      &.aon-direction-column {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-family: variables.$main-font-regular;

                        input[type="radio"] {
                          opacity: 0;
                          width: 0;
                          position: absolute;
                          box-sizing: border-box;
                        }

                        input[type="radio"] + label {
                          font-family: variables.$main-font-regular;
                          font-weight: variables.$main-font-weight-light;
                          margin-bottom: 0px;
                          position: relative;
                          line-height: 24px;
                          font-size: 16px;
                          vertical-align: middle;
                          box-sizing: border-box;
                        }
                        /* enabled state */
                        input[type="radio"] + label::after {
                          box-sizing: border-box;
                          content: "";
                          background: #fff;
                          border: 1px solid variables.$radio-border-color;
                          background-color: rgba(255, 255, 255, 0.8);
                          width: 24px;
                          margin-right: 0px;
                          height: 24px;
                          cursor: pointer;
                          text-align: center;
                          vertical-align: bottom;
                          border-radius: 100%;
                        }

                        input[type="radio"] + label:hover::after {
                          border: 2px solid variables.$radio-border-hover-color;
                          cursor: pointer;
                        }

                        input[type="radio"]:checked + label::after {
                          box-sizing: border-box;
                          background-color: variables.$radio-bg-checked-color;
                          border: 4px solid variables.$radio-border-checked-color;
                          box-shadow: inset 0 0 0 2.5px rgba(255, 255, 255, 0.95);
                        }

                        input[type="radio"]:checked + label:hover::after {
                          border: 4px solid variables.$radio-border-checked-color;
                        }

                        input[type="radio"]:focus + label::after {
                          outline: variables.$radio-border-checked-color auto 2px;
                        }
                        /* disabled state */
                        input[type="radio"]:disabled + label {
                          pointer-events: none;
                          opacity: 0.5;
                        }
                        /* error state */
                        .error input[type="radio"] + label::after {
                          border: 1px solid variables.$feedback-color-error;
                        }

                        .error input[type="radio"] + label:hover::after {
                          border: 2px solid variables.$feedback-color-error;
                        }

                        .error input[type="radio"]:checked + label::after {
                          background-color: variables.$feedback-color-error;
                          box-shadow: inset 0 0 0 2.5px rgba(255, 255, 255, 0.95);
                          border: 4px solid variables.$feedback-color-error;
                        }

                        .aon-form-radio-label {
                          display: flex;
                          flex-direction: column;
                          align-items: center;

                          span {
                            @include mixins.paragraph;
                            font-family: variables.$main-font-bold;
                            font-size: variables.$text-size-lg;
                            line-height: 28px;
                            margin-bottom: 0px;
                          }

                          &::before {
                            margin-right: 0px;
                          }
                        }
                      }
                    }

                    .aon-required,
                    .aon-optional {
                      display: none;
                    }
                  }
                }

                &.aon-direction-col {
                  .aon-form-radio-group {
                    .aon-form-radio {
                      border: 1px solid variables.$radio-container-border-color-unselected;
                      border-radius: 5px;
                      padding: 10px;
                    }
                  }
                }
              }

              legend,
              .aon-form-label {
                margin-bottom: 12px;
              }
            }
          }

          .aon-icon-info {
            cursor: pointer;

            &::before {
              content: "\f05a";
              font-family: variables.$font-awesome;
              font-weight: variables.$main-font-weight-light;
            }
          }

          .tooltip .tooltip-inner {
            background-color: #4C4D4F;
          }

          .tooltip .arrow::before {
            border-top-color: #4C4D4F;
          }
        }

        aon-input-password {

          .aon-show-text,
          .aon-hide-text {
            color: variables.$modal-input-icon-color;
          }
        }
      }
    }
  }
  /*BUTTON CODE IS HERE TO CATER FOR LAYOUT WHERE BUTTONS ARE NOT INSIDE A SABLE FLOW CARD*/
  .flow-group {

    div[class*="offset"] {
      margin-left: 0px;
    }

    button:not(:first-child) {
      margin-left: 20px;

      @media only screen and (max-width:variables.$site-body-width-S) {
        margin-left: 0px;
        margin-top: 20px;
      }
    }
  }

  .aon-quote-flow-modal-link {
    margin-top: 12px;
    margin-bottom: 20px;
  }

  .aon-activity-reviewpurchase {
    .sable-flow-divider {
      display: none;
    }
  }
}

//Sable form within modal specific styling
.modal-content {
  sable-flow {
    .bg-dark {
      .container {
        &.navbar-brand {
          h4 {
            //We need H3 styling for modal header so re-apply our H3 style here
            @include mixins.heading($fontfamily: variables.$main-font-regular, $fontsize: 24px, $lineheight: 32px, $weight: variables.$main-font-weight-bold);

            &.secondary {
              @include mixins.heading($fontfamily: variables.$main-font-regular, $fontsize: 22px, $lineheight: 24px, $weight: variables.$main-font-weight-bold);
            }

            &.color-2 {
              color: variables.$header-color-two;
            }

            &.color-link {
              color: variables.$header-color-four;
            }
          }
        }
      }
    }

    .container {
      .card {
        border: 0px;
        padding: 0px;
        box-shadow: none;
      }
    }
  }

  .sable-form,
  sable-form {
    .aon-static-content {
      &.aon-label {
        @extend label;
      }

      &.aon-value {
        margin-bottom: variables.$read-only-value-margin-bottom;
        @extend .input-content;
      }
    }

    sable-input-selector {
      .aon-form-component {
        position: relative;


        .aon-icon-edit::before {
          content: "\f044";
          font-family: variables.$font-awesome;
          position: absolute;
          top: -2px;
          right: -10px;
          font-weight: variables.$main-font-weight-light;
          color: variables.$link-default-color;
          cursor: pointer;
          font-size: 18px;
        }

        .aon-link-primary {
          .aon-icon-view::before {
            display: inline-block;
            content: "\f002";
            font-family: variables.$font-awesome;
            font-weight: variables.$main-font-weight-normal;
            margin-right: 8px;
            cursor: pointer;
          }
        }
      }
    }

    .aon-form-component {
      margin-bottom: variables.$form-group-margin-bottom;

      .input-group {
        .aon-form-input {
          position: relative;
          flex: 1 1 auto;
          width: 1%;
          min-width: 0;
          margin-bottom: 0;
        }
      }

      .aon-required,
      .aon-optional {
        font-family: variables.$main-font-regular;
        font-style: italic;
        font-weight: variables.$main-font-weight-light;
        line-height: 24px;
        color: variables.$input-desc-text-color;
      }

      &.aon-form-input-invalid {
        .aon-form-input {
          border-color: variables.$feedback-color-error;
        }

        .aon-form-label {
          color: variables.$feedback-color-error;
        }

        input[type="radio"] + label::before {
          border-color: variables.$feedback-color-error;
        }
      }

      .aon-alert {
        &.aon-contextual-alert {
          @media only screen and (min-width: variables.$site-body-width-S) {
            max-width: variables.$form-control-max-width;
          }

          display: flex;
          align-items: center;
          background-color: variables.$feedback-color-error-lighter;

          i {
            color: variables.$feedback-color-error-darker;

            &::before {
              content: "\f071";
              font-family: variables.$font-awesome;
              font-style: normal;
              font-weight: variables.$main-font-weight-bold;
            }
          }
        }
      }
    }

    .sable-input-radio,
    sable-input-radio {
      .aon-form-component {
        .aon-form-radio-group {
          .aon-form-radio {
            .aon-form-radio-label {
              display: flex;
              align-items: flex-start;

              &::before {
                flex-shrink: 0;
              }

              P {
                margin: 0px !important;

                &.vet-address {
                  font-size: variables.$text-size-sm;
                  line-height: 18px;
                  color: variables.$paragraph-color-two;
                }
              }
            }
          }
        }

        &.aon-border {
          &.aon-direction-row {
            .aon-form-radio-group {
              display: flex;
              flex-direction: row;

              .aon-form-radio {
                margin-right: 20px;

                &:last-child {
                  margin-right: 0px;
                }

                border: 1px solid variables.$radio-container-border-color-unselected;
                border-radius: 12px;
                padding: 20px;
                min-width: 150px;

                &.selected {
                  border: 2px solid variables.$radio-container-border-color-selected;
                  padding: 19px;
                }

                &.aon-icon-dog {
                  .aon-form-radio-label {
                    span {
                      display: flex;
                      flex-direction: column;
                      align-items: center;

                      &::after {
                        content: url("/assets/images/form-controls/pet-med-dog-marine.svg");
                        background-repeat: no-repeat;
                        margin-top: 6px;
                        margin-bottom: 8px;
                      }
                    }
                  }
                }

                &.aon-icon-cat {
                  .aon-form-radio-label {
                    span {
                      display: flex;
                      flex-direction: column;
                      align-items: center;

                      &::after {
                        content: url("/assets/images/form-controls/pet-med-cat-marine.svg");
                        background-repeat: no-repeat;
                        margin-top: 6px;
                        margin-bottom: 8px;
                      }
                    }
                  }
                }

                &.aon-direction-column {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  font-family: variables.$main-font-regular;

                  input[type="radio"] {
                    opacity: 0;
                    width: 0;
                    position: absolute;
                    box-sizing: border-box;
                  }

                  input[type="radio"] + label {
                    font-family: variables.$main-font-regular;
                    font-weight: variables.$main-font-weight-light;
                    margin-bottom: 0px;
                    position: relative;
                    line-height: 24px;
                    font-size: 16px;
                    vertical-align: middle;
                    box-sizing: border-box;
                  }
                  /* enabled state */
                  input[type="radio"] + label::after {
                    box-sizing: border-box;
                    content: "";
                    background: #fff;
                    border: 1px solid variables.$radio-border-color;
                    background-color: rgba(255, 255, 255, 0.8);
                    width: 24px;
                    margin-right: 0px;
                    height: 24px;
                    cursor: pointer;
                    text-align: center;
                    vertical-align: bottom;
                    border-radius: 100%;
                  }

                  input[type="radio"] + label:hover::after {
                    border: 2px solid variables.$radio-border-hover-color;
                    cursor: pointer;
                  }

                  input[type="radio"]:checked + label::after {
                    box-sizing: border-box;
                    background-color: variables.$radio-bg-checked-color;
                    border: 4px solid variables.$radio-border-checked-color;
                    box-shadow: inset 0 0 0 2.5px rgba(255, 255, 255, 0.95);
                  }

                  input[type="radio"]:checked + label:hover::after {
                    border: 4px solid variables.$radio-border-checked-color;
                  }

                  input[type="radio"]:focus + label::after {
                    outline: variables.$radio-border-checked-color auto 2px;
                  }
                  /* disabled state */
                  input[type="radio"]:disabled + label {
                    pointer-events: none;
                    opacity: 0.5;
                  }
                  /* error state */
                  .error input[type="radio"] + label::after {
                    border: 1px solid variables.$feedback-color-error;
                  }

                  .error input[type="radio"] + label:hover::after {
                    border: 2px solid variables.$feedback-color-error;
                  }

                  .error input[type="radio"]:checked + label::after {
                    background-color: variables.$feedback-color-error;
                    box-shadow: inset 0 0 0 2.5px rgba(255, 255, 255, 0.95);
                    border: 4px solid variables.$feedback-color-error;
                  }

                  .aon-form-radio-label {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span {
                      @include mixins.paragraph;
                      font-family: variables.$main-font-bold;
                      font-size: variables.$text-size-lg;
                      line-height: 28px;
                      margin-bottom: 0px;
                    }

                    &::before {
                      margin-right: 0px;
                    }
                  }
                }
              }

              .aon-required,
              .aon-optional {
                display: none;
              }
            }
          }

          &.aon-direction-col {
            .aon-form-radio-group {
              .aon-form-radio {
                border: 1px solid variables.$radio-container-border-color-unselected;
                border-radius: 5px;
                padding: 10px;
              }
            }
          }
        }

        legend,
        .aon-form-label {
          margin-bottom: 12px;
        }
      }
    }
  }
}

.summary-container,
.q-com-sleeve {
  sable-dataview {
    .accordion {
      .expansion-panel {
        &.accordion-item {
          .accordion-header {
            .header-sleeve {
              .header-text {
                font-family: variables.$main-font-regular;
                font-size: 16px;
                font-weight: variables.$main-font-weight-normal;
                line-height: 24px;
                margin-bottom: 0px;
                color: variables.$expansion-panel-header-color;

                &::first-letter {
                  text-transform: uppercase;
                }
              }

              i {
                font-size: 16px;
              }
            }
          }

          .accordion-body {
            margin-top: 12px;
            margin-bottom: 6px;
            color: variables.$expansion-panel-text-color;
            font-family: variables.$main-font-regular;
            font-size: 16px;
            font-weight: variables.$main-font-weight-normal;
            line-height: 24px;
          }
        }
      }
    }
  }
}

/*Styling of quote model Start*/
app-quote-summary-modal {

  .modal-body {
    label {
      padding-top: 20px;
      margin-bottom: 16px !important;
    }

    .modal-div {
      padding-top: 0px;
      padding-bottom: 0px;

      .purchase-group-block {
        border-bottom: 1px solid variables.$bg-color-gray04;
        margin-bottom: 0px;
        padding-bottom: 20px;

        &:empty {
          display: none;
        }

        &:last-child {
          border-bottom: none;
          padding-bottom: 0px;

          .purchase-block {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  app-quote-summary {
    .summary-container {
      margin-bottom: 0px;

      .list-label-pair {
        padding-right: 20px;

        .sable-label {
          font-weight: variables.$main-font-weight-bold;
          flex-basis: 65%;
        }

        .sable-value {
          flex-basis: 35%;
        }
      }
    }
  }
}

/*Styling of quote model End*/

app-quote-summary {
  .summary-container {
    margin-bottom: 20px;
  }

  .summary-container.quote-summary {
    word-break: break-word;

    .aon-email {
      .sable-label {
        max-width: 150px;
        width: 100%;
      }
    }
  }

  .modal-div {
    padding-top: 10px;
    padding-bottom: 10px;

    .purchase-group-block {
      margin-bottom: 15px;
      padding-bottom: 5px;

      .purchase-block {
        border-bottom: 1px solid silver;
        margin-bottom: 10px;
      }
    }
  }
}

.modal-dialog .summary-container.quote-summary {
  .aon-email {
    .sable-label {
      max-width: 300px !important;
    }
  }
}

.aon-icon-calendar {
  position: absolute;
  right: 12px;
  line-height: 44px !important;
  cursor: pointer;

  &::before {
    content: "\f133";
    font-family: variables.$font-awesome;
    font-style: normal;
    font-weight: variables.$main-font-weight-bold;
    color: variables.$icon-default-color;
  }
}

.aon-pre-text {
  color: variables.$input-desc-text-color;
  margin-bottom: 4px;
}

.aon-post-text {
  margin-top: 4px;
}

.aon-form-component {
  &.aon-post-warning {
    margin-bottom: 0px !important;
  }
}

.aon-static-content {
  &.aon-warning {
    color: variables.$input-desc-text-color;
  }
}

.aon-form-label {
  flex-direction: column !important;
  align-items: normal !important;
  margin-bottom: 0px !important;

  .alert {
    &.contextual {
      &.error {
        font-family: variables.$main-font-regular;
      }
    }
  }

  .aon-form-label-text {
    margin-bottom: 4px;
    display: block;
  }
}

label {
  &.aon-form-radio-label {
    &.delete-reason-input-label::before {
      vertical-align: middle !important;
    }
  }
}

@use '../3-helpers/variables';

body {

  //Added to support a simplistic data table, most tables on the application are bespoke based on context, this defines a basic template
  .basic-data-table {
    width: 100%;
    border: 1px solid variables.$tbs-table-row-border-color;
    border-radius: 12px;
    border-collapse: separate;
    font-size: variables.$tbs-table-data-font-size;

    .currency-cell {
      text-align: right;
    }

    thead {
      tr {
        th {
          padding-top: variables.$tbs-table-cell-padding-y;
          padding-bottom: variables.$tbs-table-cell-padding-y;
          border-bottom: 1px solid variables.$tbs-table-row-border-color;

          &:first-child {
            padding-left: variables.$tbs-table-cell-padding-x;
          }

          &:last-child {
            padding-right: variables.$tbs-table-cell-padding-x;
          }
        }
      }
    }

    tbody {
      tr {

        td,
        th {
          padding-top: variables.$tbs-table-cell-padding-y;
          padding-bottom: variables.$tbs-table-cell-padding-y;
          border-bottom: 1px solid variables.$tbs-table-row-border-color;

          &:first-child {
            padding-left: variables.$tbs-table-cell-padding-x;
          }

          &:last-child {
            padding-right: variables.$tbs-table-cell-padding-x;
          }
        }

        &:last-child>td {
          border-bottom: none;
        }
      }

      tr:nth-child(odd) {
        td {
          background-color: variables.$tbs-table-row-background;
        }
      }
    }
  }

  table,
  .table {
    overflow-x: scroll;
    color: variables.$high-emphasis-primary-text-color;

    caption {
      caption-side: top;
      text-align: center;
    }
  }

  .aon-table-style {
    overflow-x: auto;

    table {
      background: variables.$white;
      border: 1px solid variables.$box-shadow-color;
      border-radius: 12px;
      overflow-x: scroll;
      width: 100%;

      thead {
        tr {
          border-bottom: 1px solid variables.$bg-color-smoke;

          &:last-child {
            border-bottom: 1px solid variables.$bg-color-smoke;
          }

          th {
            padding: 16px 12px;
            border-right: 1px solid variables.$bg-color-smoke;

            &:last-child {
              border-right: none;
            }
          }
        }
      }

      tr {
        border-bottom: 1px solid variables.$bg-color-smoke;

        &:last-child {
          border-bottom: none;
        }

        th {
          font-weight: variables.$main-font-weight-bold;

          &:first-child {
            padding-left: 32px;
          }

          &:last-child {
            padding-right: 32px;
          }
        }

        td {
          border: none;
          padding: 16px 12px;
          color: variables.$paragraph-color-one;

          a {
            color: variables.$link-default-color !important;
          }

          &:first-child {
            padding-left: 32px;
          }

          &:last-child {
            padding-right: 32px;
          }
        }
      }
    }
  }
}
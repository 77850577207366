@use '../../global/3-helpers/variables' as variables;

/* disabled state */
.aon-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.aon-disabled input:disabled {
  opacity: 0.5;
  background-color: variables.$textinput-disabled-bg-color;
  cursor: not-allowed;
}

.k-input .k-input-inner,
input[type="password"] {
  height: 44px;
  width: 100%;
  font-family: variables.$main-font-regular;
  font-weight: variables.$main-font-weight-normal;
  font-size: 16px;
  line-height: 18px;
  color: variables.$textinput-text-color;
  background-color: variables.$textinput-bg-color;
  border: 1px solid variables.$textinput-border-color;
  border-radius: 4px;
  padding: 12px 40px 12px 12px;

  @media only screen and (min-width: variables.$site-body-width-S) {
    max-width: variables.$form-control-max-width;
  }
}

// We used box-shadow for datepickers instead of border to avoid flickering issue on hover
.k-datepicker,
.k-multiselect {
  box-shadow: 0 0 0 1px variables.$textinput-border-color;
  border-radius: 4px;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

.k-multiselect:not(:disabled):focus-within,
.k-numerictextbox .k-input-inner:not(:disabled):focus-within,
aon-input-date:not(.disabled) .k-datepicker:focus-within,
input[type="password"]:not(:disabled):focus-within {
  outline: 1px auto variables.$textinput-border-focus-color;
  border: 1px solid variables.$textinput-border-focus-color;
}

.k-numerictextbox .k-input-inner:not(:disabled):not(:focus-within):hover,
input[type="password"]:not(:disabled):not(:focus-within):hover {
  border: 1px solid variables.$textinput-border-hover-color;
  outline: 1px auto variables.$textinput-border-hover-color;
}

.k-multiselect:not(:disabled):hover,
aon-input-date:not(.disabled) .k-datepicker:hover {
  box-shadow: 0 0 0 2px variables.$textinput-border-hover-color;
}

.inverse-content-block,
.inverse {

  .k-numerictextbox .k-input-inner,
  input[type="password"] {
    color: variables.$textinput-text-color-inverse;
    border: 1px solid variables.$textinput-border-color-inverse;
  }

  .k-multiselect,
  .k-datepicker {
    color: variables.$textinput-text-color-inverse;
    box-shadow: 0 0 0 1px variables.$textinput-border-color-inverse;
  }

  .k-svg-icon {
    color: variables.$textinput-text-color-inverse !important;
  }

  .k-dateinput {
    input {
      color: variables.$textinput-text-color-inverse;
    }

    ::placeholder {
      color: variables.$textinput-text-color-inverse;
    }
  }

  .k-numerictextbox .k-input-inner:not(:disabled):hover,
  input[type="password"]:not(:disabled):hover {
    border: 1px solid variables.$textinput-border-color-inverse;
    outline: 1px auto variables.$textinput-border-color-inverse;
  }

  .k-multiselect:not(:disabled):focus-within,
  .k-numerictextbox .k-input-inner:not(:disabled):focus-within,
  .k-datepicker:not(:disabled):focus-within,
  input[type="password"]:not(:disabled):focus-within {
    border: 1px solid variables.$textinput-border-focus-color-inverse;
    outline: 1px auto variables.$textinput-border-focus-color-inverse;
  }

  .k-multiselect:not(:disabled):hover,
  .k-datepicker:not(:disabled):hover {
    border: 1px solid variables.$textinput-border-color-inverse;
    outline: 1px auto variables.$textinput-border-color-inverse;
  }

  .aon-input-password-wrapper {
    .aon-icon:focus {
      outline: 2px solid variables.$textinput-text-color-inverse;
      outline-offset: 2px;
    }
  }
}

.inverse {
  aon-input-password span {
    color: variables.$textinput-icon-color-inverse;
  }
}

.aon-form-label.aon-label-error span {
  color: variables.$feedback-color-error-darker;
}

aon-input-radio-list {
  display: inherit;
  flex-direction: inherit;
}

.k-list-item.k-selected,
.k-list-item.k-selected:hover {
  background-color: variables.$textinput-border-focus-color;
}

.k-input:focus-within {
  box-shadow: none;
}

.k-input {
  background-color: inherit;
}
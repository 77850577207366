﻿@use '../3-helpers/variables';

.progress {
    background-color: variables.$secondary-color-navy;
    border-radius: 0;
    height: 10px;
}

.progress-bar {
    &.bg-success {
        background-color: variables.$feedback-color-success !important;
    }

    &.bg-primary {
        background-color: variables.$secondary-color-lagoon-lighter !important;
    }
}
@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.tag {
  margin-left: 8px;
  padding: 4px 2px;
  font-size: variables.$text-size-xs;
  line-height: variables.$text-size-xs;
  border-radius: 4px;
  font-weight: variables.$main-font-weight-bold;
  display: inline-block;

  &.teal-tag-wrapper {
    background-color: variables.$tag-teal-bg-color;
    color: variables.$tag-teal-text-color;


    i {
      vertical-align: middle;
      color: variables.$tag-teal-text-color;
    }
  }

  &.green-tag-wrapper {
    background-color: variables.$tag-green-bg-color;
    color: variables.$tag-green-text-color;

    i {
      vertical-align: middle;
      color: variables.$tag-green-text-color;
    }
  }

  &.red-tag-wrapper {
    background-color: variables.$tag-red-bg-color;
    color: variables.$tag-red-text-color;

    i {
      vertical-align: middle;
      color: variables.$tag-red-text-color;
    }
  }

  &.yellow-tag-wrapper {
    background-color: variables.$tag-yellow-bg-color;
    color: variables.$tag-yellow-text-color;

    i {
      vertical-align: middle;
      color: variables.$tag-yellow-text-color;
    }
  }

  &.gray-tag-wrapper {
    background-color: variables.$tag-gray-bg-color;
    color: variables.$tag-gray-text-color;

    i {
      vertical-align: middle;
      color: variables.$tag-gray-text-color;
    }
  }
}